import React from 'react'
import mainImage from '../../../../media/images/3657664.webp'
import './header.css'
import './headerMediaQuery.css'
import HeaderText from './components/headerText/headerText'


const Header = () => {
  return (
    <header>
        <HeaderText />
        <img src={mainImage}></img>
    </header>
  )
}

export default Header