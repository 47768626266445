import React, { useEffect, useState } from 'react'
import './LinkComponent.css'
import './LinkComponentMediaQuery.css'
import { Icon } from '@iconify/react';
import { Bounce, toast } from 'react-toastify';

const LinkComponent = (props) => {
    const [link, setLink] = useState()
    const [title, setTitle] = useState()
    const [color, setColor] = useState()
    const [fontSize, setFontSize] = useState(24)
    
    const errorFunction = (content) => {
        toast.error(content, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        })
    }

    const setLinkComponent = () => {
        if(title.length > 200 || title.length < 0){
            errorFunction("Długość tytułu powinna zawierać się w przedziale 0-200 znaków")
        }
        else if(link.length > 200 || link.length < 0){
            errorFunction("Długość tytułu powinna zawierać się w przedziale 0-500 znaków")
        }
        else{
            props.setNewLink(link, title, color, fontSize)
        }
        
    }

    const setNewData = () => {
        if(title.length > 200 || title.length < 0){
            errorFunction("Długość tytułu powinna zawierać się w przedziale 0-200 znaków")
        }
        else if(link.length > 200 || link.length < 0){
            errorFunction("Długość tytułu powinna zawierać się w przedziale 0-500 znaków")
        }
        else{
            props.editBlock(['link', props.data[0], link, title, color, fontSize])
        }   
    }

    useEffect(() => {
        if(props.edit == true){
            setLink(props.data[2])
            setTitle(props.data[3])
            setColor(props.data[4])
            setFontSize(props.data[5])
        }
    }, [])


    const closeDialog = () => {
        setLink(props.data[2])
        setTitle(props.data[3])
        setColor(props.data[4])
        setFontSize(props.data[5])
        document.getElementById('edit-element-dialog').close()
    }

    return (
        <div className='link-component-body'>
            <div className='options'>
                <label>Wielkość (px): <input defaultValue={props.data ? Number(props.data[5]) : 24} type="number"  onChange={(e) => setFontSize(e.target.value > 100 ? 100 : e.target.value < 0 ? 1 : e.target.value)}></input></label>
                <input type='color' defaultValue={color} onChange={(e) => setColor(e.target.value)}></input>
                
                <button className='accept' onClick={props.edit == true ? setNewData : setLinkComponent}><Icon icon="mingcute:check-fill" /></button>
                <button className='delicine' onClick={props.edit == true ? closeDialog : props.clearComponentArray}><Icon icon="octicon:x-16" /></button>
            </div>
            <div className='link-input'>
                <input placeholder='Treść' defaultValue={props.data && props.data[3]} onChange={(e) => setTitle(e.target.value)}></input>
                <input placeholder='Adres URL' defaultValue={props.data && props.data[2]} onChange={(e) => setLink(e.target.value)}></input>
            </div>
        </div>
    )
}

export default LinkComponent