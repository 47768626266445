import React, { useEffect, useState } from 'react'
import Menu from '../../components/menu/menu'
import './singleOfferSite.css'
import './singleOfferSiteMediaQuery.css'
import { Icon } from '@iconify/react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import MenuCompact from '../../components/menuCompact/menuCompact';

const SingleOfferSite = () => {
  const [offerList, setOfferList] = useState([])
  const offerCollection = collection(db, 'offers')
  const navigate = useNavigate()

  const getOfferList = async (offerID) => {
    try {
      const data = await getDocs(offerCollection)

      let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

      setOfferList(finalData.filter((offer) => {
        return (
          offer.id && offer.id.includes(offerID)
        )
      }))
    }
    catch (err) {
      console.err(err)
    }
  }

  const navigateToResponse = () => {
    const urlParams = new URLSearchParams(window.location.search)
    let offerID = urlParams.get('offerId')
    navigate(`/responseOffer?offerId=${offerID}`)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    let offerID = urlParams.get('offerId')
    getOfferList(offerID)

  }, [])

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  return (

    <section className='single-offer-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}

      <Link className='back-to-offers' to="/offer"> <Icon icon="material-symbols:arrow-left" /> Powrót do ogłoszeń </Link>

      {offerList.map((offer) => (
        <div className='single-offer-details' key={offer.id}>
          <p>Dodane: {offer.date}</p>
          <h1>{offer.title}</h1>
          <div className='single-offer-info'>
            <div className='offer-tech-pos'>
              <p><span>Stanowiska:</span> {offer.positions.map((position) => (position + ', '))}</p>
              <p><span>Technologie:</span> {offer.technologies.map((tech) => (tech + ', '))}</p>
            </div>

            <h2>OPIS</h2>

            <p>{offer.text}</p>

            <div className='bottom-offer'>
              <p>Dodane przez: <span>{offer.author}</span></p>
              <button onClick={navigateToResponse}>Zgłoś chęć pomocy</button>
            </div>
          </div>
        </div>
      ))}

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>
    </section>
  )
}

export default SingleOfferSite