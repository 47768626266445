import React, { useRef, useState } from 'react'
import './menuCompact.css'
import './menuCompactMediaQuery.css'
import { Link, useNavigate } from 'react-router-dom'
import { auth, db_realtime } from '../../config/firebase'
import { signOut } from 'firebase/auth'
import { Icon } from '@iconify/react';
import { useEffect } from 'react'
import { child, onValue, push, ref, remove, set, update } from 'firebase/database'
import logoLarge from '../../media/images/CodeXperience-long.webp'

const MenuCompact = () => {
    const [notifications, setNotifications] = useState([])
    const [authLoaded, setAuthLoaded] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [actualUser, setActualUser] = useState('');
    let menuVisibility = 0;

    useEffect(() => { // function loading username to state
        const checkAuthState = async () => {
            await new Promise((resolve) => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    if (user) {
                        setDisplayName(user.uid);
                        setActualUser(user)
                        resolve();
                    }
                    unsubscribe();
                });
            });

            setAuthLoaded(true);
        };

        checkAuthState();
    }, []);

    const getNotifiCations = async () => { //function to get notifications from db
        if (displayName) {
            const nt = ref(db_realtime, `/notifications/${displayName}/`);
            console.log(`/notifications/${displayName}/`)

            onValue(nt, (snapshot) => {
                if (snapshot.val() != null) {
                    let notifications = Object.values(snapshot.val());
                    console.log(notifications)

                    let newNt = notifications.filter((notification) => {
                        return (
                            notification.status && notification.status == 'oczekujace'
                        )
                    })

                    if (newNt.length > 0) {
                        document.querySelector('.notification-box').classList.add('notification-exists')
                    }
                    else {
                        document.querySelector('.notification-box').classList.remove('notification-exists')
                    }

                    setNotifications(notifications)
                }
                else {
                    if (document.querySelector('.notification-box').classList.contains('notification-exists')) {
                        document.querySelector('.notification-box').classList.remove('notification-exists')
                    }

                    setNotifications([])
                }
            })
        }
    }

    const changeMenuVisibility = () => {
        const menu = document.querySelector('.notifications-container')
        if (menuVisibility == 0) {
            menu.classList.remove('nt-box-closed')
            menuVisibility = 1
        }
        else if (menuVisibility == 1) {
            menu.classList.add('nt-box-closed')
            menuVisibility = 0
        }
    }

    const acceptNotfication = (e) => {
        update(ref(db_realtime, `notifications/${displayName}/nt${e.currentTarget.id.slice(7)}`), {
            status: "zaakceptowano",
        })


        getNotifiCations()
    }

    const deleteNotification = (e) => {
        remove(ref(db_realtime, `notifications/${displayName}/nt${e.currentTarget.id.slice(6)}`))


        getNotifiCations()
    }

    const menuRef = useRef()

    const showHiddenMenu = (e) => {
        if(e.currentTarget.classList.contains('showed')){
            e.currentTarget.classList.remove('showed')
            menuRef.current.classList.add('hidden')
        }
        else{
            e.currentTarget.classList.add('showed')
            menuRef.current.classList.remove('hidden')
        }
    }

    

    useEffect(() => {
        getNotifiCations()
    }, [authLoaded, displayName])

    return (
        <>
            <nav>
                {/* <Link className='logo-link' to="/"><p className='main-logo-compact'><span>C</span>od<span>eX</span>perience</p></Link> */}
                <div className='compact-logo-main'>
                    <img src={logoLarge}/>
                </div>

                <div className='menu-show-button' onClick={(e) => showHiddenMenu(e)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <div className='hidden-menu hidden' ref={menuRef}>
                    {window.location.origin + window.location.pathname != 'http://localhost:3000/login' && window.location.origin + window.location.pathname != 'http://localhost:3000/register' ? actualUser.email ?
                        <div className='user-box'>
                            <Link to="/userPanel"><button><div className='image-inside-button' style={{ backgroundImage: `url(${actualUser.photoURL})` }}></div><p>{actualUser.displayName}</p></button></Link>
                            <div className='notification-box' id='notificxation-box' onClick={changeMenuVisibility}>
                                <Icon icon="ic:sharp-notifications" />
                            </div>
                            <div className='notifications-container nt-box-closed'>
                                {
                                    notifications.length > 0 ?
                                        notifications.map((nt) => (
                                            <div className='single-notification' key={nt.index}>
                                                <Icon icon="quill:paper" className='nt-icon' />
                                                <div>
                                                    <p className='nt-category'>{nt.category}</p>
                                                    <p className='nt-desc'>{nt.text}</p>
                                                </div>
                                                <Icon icon="ph:x" className='nt-close' id={"close-" + nt.index} onClick={(e) => deleteNotification(e)} />
                                                {
                                                    nt.status == "oczekujace" && <Icon icon="tabler:check" className='nt-check' id={"accept-" + nt.index} onClick={(e) => acceptNotfication(e)} />
                                                }

                                            </div>
                                        ))
                                        :
                                        <p className='empty-nt-box'>Brak powiadomień</p>
                                }
                            </div>
                        </div>

                        :

                        <Link title="Przycisk zaloguj" to="/login">
                            <button>
                                Zaloguj się
                            </button>
                        </Link>

                        :

                        <></>}
                    <div className='menu-buttons'>
                        <Link to="/offer" title='Przycisk aplikuj'>Aplikuj</Link>
                        <Link to="/projectsSite" title='Publiczne projekty'>Publiczne projekty</Link>
                        {window.location.origin + window.location.pathname == 'http://localhost:3000/' ? <a title='Przycisk o projekcie' href='/#oProjekcie'>O projekcie</a> : <Link title='Przycisk o projekcie' to='/#oP'>O projekcie</Link>}
                        <Link to="/contact" title='Przycisk Kontakt'>Kontakt</Link>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default MenuCompact;
