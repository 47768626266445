import React, { useEffect, useState } from 'react'
import Menu from '../../components/menu/menu'
import './responseOfferSite.css'
import './responseOfferSiteMediaQuery.css'
import { addDoc, collection, getDoc, getDocs } from 'firebase/firestore'
import { db, auth, db_realtime } from '../../config/firebase'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { onValue, ref, set } from 'firebase/database'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import MenuCompact from '../../components/menuCompact/menuCompact'

const ResponseOfferSite = () => {
  const offerCollection = collection(db, 'offers') //firebase collection - offers
  const volunteersCollection = collection(db, 'volunteers') //firebase collection - volunteers

  const [offerList, setOfferList] = useState([])
  const [postAuthor, setPostAuthor] = useState('');
  const navigate = useNavigate()

  let sameVolunters = "";
  let date = new Date()
  // const [double, setDouble] = useState(false)
  let double = false
  // const [myOffer, setMyOffer] = useState(false)
  let myOffer = false

  const [notificationIndex, setNewNotificationIndex] = useState(0)

  const setNewNotification = async (userUID) => {
    if (userUID != undefined && userUID != '') {
      set(ref(db_realtime, `/notifications/${userUID}/nt${(Number(notificationIndex) + Number(1))}`), {
        date: date.getDate() + '.' + (date.getMonth() + 1 > 0 ? date.getMonth() + 1 : "0" + date.getMonth() + 1) + '.' + (Number(date.getYear()) + 1900),
        type: 'newVolunteer',
        text: 'Ktoś odpowiedział na twoje zgłoszenie, sprawdź listę zgłoszeń',
        index: Number(notificationIndex) + 1,
        status: 'oczekujace',
        category: "Zgłoszenie",
      })
    }
  }

  const setNotificationIndex = async (userUID) => {
    if (userUID != undefined && userUID != '') {
      const nt = ref(db_realtime, `/notifications/${userUID}`)

      onValue(nt, (snapshot) => {
        if (snapshot.val() != null) {
          let notifications = Object.values(snapshot.val());
          let indexArray = []

          if (notifications.length > 0) {
            notifications.forEach((nt) => {
              indexArray.push(nt.index)
            })
          }
          else {
            setNewNotificationIndex(0)
          }

          let firstIndex = 0;

          indexArray.forEach((index) => {
            if (index > firstIndex) firstIndex = index
          })

          setNewNotificationIndex(firstIndex)

        }
        else {
          setNewNotificationIndex(0)
        }
      })
    }
  }


  const setOfferListState = async (offers) => {  // async function to set offer list state
    setOfferList(offers)
  }

  const getAdvertisersUID = async (offers) => { //async function to get UID author of post
    let author = offers.map(offer => offer.author)

    const arrayUsers = ref(db_realtime, 'USERS');
    onValue(arrayUsers, (snapshot) => {
      let data = Object.values(snapshot.val()).filter((row) => {
        return (
          row.name && row.name.includes(author[0])
        )
      })

      setPostAuthor(data[0].UID)
      setNotificationIndex(data[0].UID)
    })
  }

  const getOfferList = async () => { //async function to get offer with specific id from link
    try {
      const urlParams = new URLSearchParams(window.location.search)
      let offerID = urlParams.get('offerId')

      const data = await getDocs(offerCollection)

      let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

      let offers = finalData.filter((offer) => {
        return (
          offer.id && offer.id.includes(offerID)
        )
      })

      await setOfferListState(offers)
      await getAdvertisersUID(offers)
    }
    catch (err) {
      console.err(err)
    }
  }

  const getSameVolunteer = async () => {
    
    let volunters = await getDocs(volunteersCollection)
    sameVolunters = volunters.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

    let filtered = sameVolunters.filter((volunteer) => {
      return (
        volunteer.author && volunteer.author.includes(auth.currentUser.uid) && volunteer.offerId.includes(offerList[0]?.id)
      )
    })

    if (filtered.length > 0) {
      double = true
    }
    else {
      double = false
    }
  }

  const errorFunction = (content) => {
    toast.error(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const checkIfYours = async () => {

    const urlParams = new URLSearchParams(window.location.search)
    let offerID = urlParams.get('offerId')
    
    const data = await getDocs(offerCollection)
    let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    let filtered = finalData.filter((offer) => {
      return (
        offer.authorUID && offer.authorUID.includes(auth.currentUser.uid) && offer.id.includes(offerID)
      )
    })

    console.log("Me: " + filtered.length);
    if (filtered.length == 0) {
      // setMyOffer(false)
      myOffer = false
    }
    else {
      // setMyOffer(true)
      myOffer = true
    }
  }

  const setVolunteer = async () => {
    const position = document.getElementById('responsePosition').value;
    const message = document.getElementById('responseMessage').value;
    let date = new Date()

    console.log(double);

    if (double == true) {
      errorFunction("Zgłoszenie do tego projektu zostało już wysłane")
    }
    else if (myOffer == true) {
      errorFunction("Nie możesz zgłosić się do swojego projektu")
    }
    else if (message.length > 2000) {
      errorFunction("Wiadomość nie może przekraczać 2000 znaków")
    }
    else {

      setNewNotification(postAuthor)

      try {
        addDoc(volunteersCollection, {
          offerId: offerList[0]?.id,
          projectTitle: offerList[0].title,
          role: position,
          status: true,
          text: message,
          user: auth.currentUser.displayName,
          userUID: auth.currentUser.uid,
          author: postAuthor,
          responseDate: date.getDate() + '.' + (date.getMonth() + 1 > 0 ? date.getMonth() + 1 : "0" + date.getMonth() + 1) + '.' + (Number(date.getYear()) + 1900),
        })

        navigate("/offer?code=1")
      }
      catch (err) {
        errorFunction("Błąd przetwarzania")
      }
    }
  }

  const sendMessage = async () => { //function to set new volunteer in firebase
    await getSameVolunteer();
    await checkIfYours()
    await setVolunteer()
  }

  useEffect(() => {
    if (!auth?.currentUser?.email) {
      navigate("/login?code=9")
    }
    getOfferList()

  }, [])

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])


  return (
    <section className='response-offer-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}
      <Link className='back-to-offers' to="/offer"> <Icon icon="material-symbols:arrow-left" /> Powrót do ogłoszeń </Link>
      <div className='response-offer'>
        <h1>Wyślij zgłoszenie</h1>
        <p>Podejmij decyzję o współpracy z innymi ambitnymi ludźmi. Wyślij zgłoszenie do ogłoszeniodawcy i czekaj na odpowiedź. W wiadomości napisz coś o sobie, opowiedz o tym co umiesz, a czego chciałbyś się nauczyć oraz dlaczego chcesz dołączyć do tego projektu. Potraktuj to zgłoszenie jak małe CV. Powodzenia!</p>

        <div className='response-offer-form'>
          <select name="positions" id="responsePosition">
            {offerList.map((offer) => (
              offer.positions.map((position) => (
                <option value={position} key={position}> {position} </option>
              ))
            ))}
          </select>
          <textarea placeholder='Wiadomość (max 2000 znaków)' id="responseMessage"></textarea>
          <button onClick={sendMessage}>Wyślij zgłoszenie</button>

        </div>
      </div>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>
      <ToastContainer />
    </section>
  )
}

export default ResponseOfferSite