import React, { useEffect, useRef } from 'react'
import './body.css'
import './bodyMediaQuery.css'
import BodyBlock from '../bodyBlock/bodyBlock'
import FullWidthArticle from '../fullWidthArticle/fullWidthArticle'
import officeImage from '../../../../media/images/code-4333398_1920.webp'

const Body = () => {

    const scrollToElementRef = useRef(null);

    useEffect(() => {
        if (scrollToElementRef.current && window.location.href == 'http://localhost:3000/#oP') {
          scrollToElementRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <main id='oProjekcie'>
            <div className='container-body-blocks'>
                <div className='darker-background'></div>
                <h3>Czym jest CodeXperience?</h3>
                <div className='body-blocks' ref={scrollToElementRef}>
                    <BodyBlock icon="ion:footsteps" title="Pierwsze kroki" text="Umożliwiamy niedoświadczonym stawianie pierwszych kroków w tworzeniu projektów zespołowo" />
                    <BodyBlock icon="tabler:bulb-filled" title="Doświadczenie" text="Wspieramy osoby niedoświadczone, dając im możliwość nabycia doswiadczenia" />
                    <BodyBlock icon="clarity:blocks-group-solid" title="Prostota" text="Jedyne co musisz zrobić to założyć konto i wystawić ogłoszenie lub dołączyć do projektu" />
                </div>
            </div>
            <FullWidthArticle title="Dla kogo jest CodeXperience?" text="CodeXperience powstało z myślą o poczatkujących i niedoświadczonych programistach, grafikach, UX/UI designerach itd. Od dawna brakowało oficjalnego miejsca, gdzie takie osoby mogłyby się łączyć w zespoły, pracujące nad czymś większym. Umożliwiamy zdobywanie doświadczenia, dajemy możliwość spróbowania czegoś więcej. Bedąc tu masz dwie możliwości, ale najpierw musisz założyć konto." />
            <div className='article-with-image'>
                <img src={officeImage}></img>
                <div className='right-side'>
                    <h6>Jak zacząć?</h6>
                    <p>Jeśli masz swój pomysł na projekt, śmiało, stwórz ogłoszenie i znajdź osoby, które Ci pomogą. A może nie masz jeszcze wizji tego co chcesz zrobić? Nie ma problemu, wystarczy, że wyszukasz interesujący Cię projekt i zgłosisz właścicielowi gotowość do pracy. Jest duża szansa, że wybierze właśnie Ciebie. Na co czekasz? Działaj!</p>
                </div>
            </div>
        </main>
    )
}

export default Body