import React, { useEffect, useState } from 'react'
import { db, auth } from '../../../../config/firebase'
import { getDocs, collection, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import './MyProjects.css'
import './MyProjectsMediaQuery.css'
import { Icon } from '@iconify/react';
import { Bounce, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



const MyProjects = () => {
    const projectCollection = collection(db, 'publicProjects')
    const navigate = useNavigate()
    const [projectsList, setProjectsList] = useState([])

    const errorFunction = (content) => {
        toast.error(content, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        })
    }

    const getMyProjects = async () => {
        try {
            const data = await getDocs(projectCollection)
            let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

            setProjectsList(finalData.filter((project) => {
                return (
                    project.authorID && project.authorID.includes(auth?.currentUser?.uid)
                )
            }))
        }
        catch (err) {
            errorFunction("Błąd ładowania zasobów")
        }
    }

    useEffect(() => {
        getMyProjects()
    }, [])

    const navigateToProject = (id) => {
        navigate(`/singleProject?id=${id}`)
    }

    return (
        <div className='my-projects-container'>
            <h3>Moje projekty</h3>
            <p>Przeglądaj wszystkie swoje projekty, wybierz jeden i przejdź do podglądu, gdzie będziesz mógł zarządzać swoim projektem.</p>

            <div className='projects-container'>
            {projectsList.length != 0 ?
                projectsList.map((project) => (
                    <div className='single-project' id={project.id} key={project.id} onClick={(e) => navigateToProject(e.target.id)}>
                        <h4>{project.title}</h4>
                        <p>{project.description}</p>
                    </div>
                ))
                :
                <p>Brak projektów</p>
            }
            </div>
        </div>
    )
}

export default MyProjects