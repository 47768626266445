import React, { useEffect, useReducer, useRef, useState } from 'react'
import Menu from '../../components/menu/menu'
import './contactSite.css'
import './contactSiteMediaQuery.css'
import { Icon } from '@iconify/react';
import ContactForm from './components/contactForm/contactForm'

import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuCompact from '../../components/menuCompact/menuCompact';

const ContactSite = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const emailRef = useRef()
  const topicRef = useRef()
  const msgRef = useRef()

  const successFunction = (content) => {
    toast.success(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const errorFunction = (content) => {
    toast.success(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const isEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return pattern.test(email)
  }

  const sendEmail = () => {
    if(title.length <= 0 || title.length > 100){
      errorFunction("Nieodpowiednia długość tytułu")
    }
    else if(message.length > 2000 || message.length <= 0){
      errorFunction("Nieodpowiednia długość wiadomości")
    }
    else if(!isEmail(email) || email.length > 100){
      errorFunction("Podany ciąg znaków nie jest adresem email")
    }
    else{
      const messageData = new FormData()
      messageData.append('subject', title)
      messageData.append('msg', message)
      messageData.append('email', email)
  
      fetch('https://codexperience.przybyslawice.kylos.pl/static/php/sendEmail.php', {
        method: 'POST',
        body: messageData,
      }
      ).then((response) => {
        successFunction("Wysłano wiadomość"); 
        setEmail(""); 
        setTitle(""); 
        setMessage("")

        emailRef.current.value = ""
        topicRef.current.value = ""
        msgRef.current.value = ""
      })
      .catch((err) => errorFunction("Błąd wysyłania"))
    }
  }

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  return (
    <section className='contact-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}
      <div className='contact'>
        <div className='text-for-contact'>
          <h1><span>Masz pytania?</span> Skontaktuj się z nami!</h1>
          <h2>Jeśli coś budzi twoje wątpliwości, chcesz się czegoś dowiedzieć, a może masz jakąś propozycję na zmianę, to napisz do nas. Z chęcią pomożemy!</h2>
          <p>Znajdziesz nas także na facebooku oraz instagramie!</p>
          <div className='contact-icons'>
            <Icon icon="ic:baseline-facebook" />
            <Icon icon="ri:instagram-fill" />
            <Icon icon="clarity:email-solid" />
          </div>
        </div>
        <ContactForm emailRef={emailRef} topicRef={topicRef} msgRef={msgRef} sendEmail={sendEmail} setMessage={setMessage} setEmail={setEmail} setTitle={setTitle}></ContactForm>
      </div>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>

      <ToastContainer />
    </section>
  )
}

export default ContactSite