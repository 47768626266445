import React, { useEffect, useState } from 'react'
import './DelimiterComponent.css'
import './DelimiterComponentMediaQuery.css'
import { Icon } from '@iconify/react';

const DelimiterComponent = (props) => {
    const [lineStyle, setLineStyle] = useState("solid")
    const [width, setWidth] = useState(100)
    const [align, setAlign] = useState("center")
    const [color, setColor] = useState("#000")

    const setDelimiterComponent = () => {
        props.setNewDelimiter(lineStyle, width, align, color)
    }

    const setNewData = () => {
        props.editBlock(['delimiter', props.data[0], lineStyle, width, align, color])
    }

    useEffect(() => {
        if(props.edit == true){
            setLineStyle(props.data[2])
            setWidth(props.data[3])
            setAlign(props.data[4])
            setColor(props.data[5])
        }
    }, [])

    const closeDialog = () => {
        setLineStyle(props.data[2])
        setWidth(props.data[3])
        setAlign(props.data[4])
        setColor(props.data[5])
        document.getElementById('edit-element-dialog').close()
    }

    return (
        <div className='delimiter-component-body'>
            <div className='options'>
                <select onChange={(e) => setLineStyle(e.target.value)}>
                    <option value="solid">Linia ciągła</option>
                    <option value="dashed">Linia kreskowana</option>
                    <option value="dotted">Linia kropkowana</option>
                </select>
                
                <label>Szerokość: <input type="number" max={100} defaultValue={100} onChange={(e) => setWidth(e.target.value > 100 ? 100 : e.target.value <= 0 ? 1 : e.target.value)}></input> %</label>
                
                <button onClick={() => setAlign("start")}><Icon icon="mingcute:align-left-fill" /></button>
                <button onClick={() => setAlign("center")}><Icon icon="mingcute:align-center-fill" /></button>
                <button onClick={() => setAlign("end")}><Icon icon="mingcute:align-right-fill" /></button>

                <input type='color' onChange={(e) => setColor(e.target.value)}></input>

                <button className='accept' onClick={props.edit == true ? setNewData : setDelimiterComponent}><Icon icon="mingcute:check-fill" /></button>
                <button className='delicine' onClick={props.edit == true ? closeDialog : props.clearComponentArray}><Icon icon="octicon:x-16" /></button>
            </div>
            <div className='delimiter-input' style={{justifyContent: align}}>
                <hr className='delimiter' style={{width: `${width}%`, border: `1px ${lineStyle} ${color}`}}/>             
            </div>
        </div>
    )
}

export default DelimiterComponent