import React from 'react'
import './headerText.css'
import './headerTextMediaQuery.css'
import { Link } from 'react-router-dom'

const HeaderText = () => {
  return (
    <div className='header-text'>
        <h1>Rozwijaj się jako <span>programista</span></h1>
        <h2>Twórz projekty i <span>współpracuj</span> z innymi</h2>
        <p>Zacznij już teraz!</p>

        <div className='header-text-buttons'>
            <Link to='/addOffer'><button>Stwórz projekt</button></Link>
            <a href="/#oProjekcie"><button>Jak to działa?</button></a>
        </div>
    </div>
  )
}

export default HeaderText