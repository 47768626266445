import React, { useEffect, useState } from 'react'
import Menu from '../../components/menu/menu'
import './registerSite.css'
import './registerSiteMediaQuery.css'
import computerGuyImage from '../../media/images/computer-guy.webp'
import { Link, useNavigate, useSubmit } from 'react-router-dom'

import { auth, db_realtime } from '../../config/firebase'
import { createUserWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth'
import { ref, set } from 'firebase/database'
import MenuCompact from '../../components/menuCompact/menuCompact'

const RegisterSite = () => {

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [password, setPassowrd] = useState("")
  const [rePassowrd, setRePassowrd] = useState("")

  const [regError, setRegError] = useState("")

  const signIn = async (event) => {
    event.preventDefault()

    if(password !== rePassowrd){     
      setRegError("Hasła nie są identyczne")
    }
    else if(password.length < 8){
      setRegError("Hasło jest zbyt krótkie")
    }
    else{
      try{
        await createUserWithEmailAndPassword(auth, email, password)
        await updateProfile(auth.currentUser, {displayName: name, photoURL: 'https://img.freepik.com/darmowe-wektory/ilustracja-biznesmen_53876-5856.jpg?w=740&t=st=1696339428~exp=1696340028~hmac=6caef8322652dd78bc51b84c522e7a1f20099c0a9230cdc114a31d30987b470b'})
        
        try{
          set(ref(db_realtime, `/USERS/${auth.currentUser.uid}`), {
            UID: `${auth.currentUser.uid}`,
            name: `${auth.currentUser.displayName}`,
            profileImage: `${auth.currentUser.photoURL}`
          })

          set(ref(db_realtime, `/usersChats/${auth.currentUser.uid}`), "")
        }
        catch(error){
          setRegError("Błąd w bazie danych... Spróbuj ponownie później")
        }

        await signOut(auth)
        navigate("/login?code=1")

      }
      catch(err){
        if(err.code === "auth/email-already-in-use"){
          setRegError("Email niedostępny")
        }
        else{
          setRegError("Błąd rejestracji, spróbuj ponownie później")
        }
      }
    }

  }

  useEffect(() => {
    if(auth.currentUser != null){
      navigate('/')
    }
  }, [])

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  return (
    <section className='register-section'>
      <div className='background'></div>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}

      <div className='register-container'>
        <div className='left-register'>
          <h1>Zarejestruj się</h1>
          <form>
            <p className='errors'>{regError}</p>
            <input type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} required></input>
            <input type='text' placeholder='Imię i nazwisko' onChange={(e) => setName(e.target.value)} ></input>
            <input type='password' placeholder='Hasło' onChange={(e) => setPassowrd(e.target.value)} required></input>
            <input type='password' placeholder='Powtórz hasło' onChange={(e) => setRePassowrd(e.target.value)} required></input>
            <button type='submit' onClick={signIn}>Stwórz konto</button>
          </form>

          <p>Masz już konto? <Link title='Przycisk rejestracja' to='/login'>Zaloguj się tutaj</Link></p>

        </div>
        <div className='right-register'>
          <img src={computerGuyImage} />
        </div>
      </div>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>
    </section>
  )
}

export default RegisterSite