import React, { useEffect, useState } from 'react'
import './HeadingComponent.css'
import './HeadingComponentMediaQuery.css'
import { Icon } from '@iconify/react';

const HeadingComponent = (props) => {
    const [heading, setHeading] = useState(2.125)
    const [headingSize, setHeadingSize] = useState('1')
    const [align, setAlign] = useState('left')
    const [color, setColor] = useState('black')
    const [content, setContent] = useState('')

    const setHeadingValue = (e) => {
        setHeadingSize(e.target.value)
        switch (e.target.value){
            case "1":
                setHeading(2.125)
                break
            case "2":
                setHeading(1.875)
                break
            case "3":
                setHeading(1.5)
                break
            case "4":
                setHeading(1.25)
                break
            case "5":
                setHeading(1.125)
                break
            case "6":
                setHeading(1)
                break
        }
    }

    const acceptHeading = () => {
        props.setNewHeading(headingSize, align, color, content, heading)
    }

    const setNewData = () => {
        props.editBlock(['heading', props.data[7], headingSize, align, color, content, heading])
    }

    useEffect(() => {
        if(props.edit == true){
            setHeading(props.data[6])
            setHeadingSize(props.data[2])
            setAlign(props.data[3])
            setColor(props.data[4])
            setContent(props.data[5])
        }
    }, [])

    const keyFinder = (e) => {
        if(e.key == 'Enter'){
            e.preventDefault()
        }

        if(e.key == 'b' || e.key == 'B' || e.key == 'u' || e.key == 'U' || e.key == 'i' || e.key == 'I'){
            if(e.ctrlKey){
                e.preventDefault()
            }
        }
    }

    const closeDialog = () => {
        setHeading(props.data[6])
        setHeadingSize(props.data[2])
        setAlign(props.data[3])
        setColor(props.data[4])
        setContent(props.data[5])
        document.getElementById('edit-element-dialog').close()
    }

    return (
        <div className='heading-component-body'>
            <div className='options'>
                <select value={headingSize} onChange={(e) => setHeadingValue(e)}>
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                </select>
                <button onClick={() => setAlign('left')}><Icon icon="mingcute:align-left-fill" /></button>
                <button onClick={() => setAlign('center')}><Icon icon="mingcute:align-center-fill" /></button>
                <button onClick={() => setAlign('right')}><Icon icon="mingcute:align-right-fill" /></button>

                <input type="color" value={color} onChange={(e) => setColor(e.target.value)}></input>

                <button className='accept' onClick={props.edit == true ? setNewData : acceptHeading}><Icon icon="mingcute:check-fill" /></button>
                <button className='delicine' onClick={props.edit == true ? closeDialog : props.clearComponentArray}><Icon icon="octicon:x-16" /></button>
            </div>
            <p style={{fontSize: `${heading}rem`, textAlign: align, color: color}} onKeyDown={(e) => keyFinder(e)} onInput={(e) => {setContent(e.target.innerText)} } className='content' contentEditable="true" suppressContentEditableWarning="true" spellCheck="false">{props.data && props.data[5]}</p>
        </div>
    )
}

export default HeadingComponent