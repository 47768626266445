import React, { useEffect, useState } from 'react'
import './SpacingComponent.css'
import './SpacingComponentMediaQuery.css'
import { Icon } from '@iconify/react';

const SpacingComponent = (props) => {
    const [height, setHeight] = useState(100)

    const setSpacingComponent = () => {
        props.setNewSpacing(height)
    }

    const setNewData = () => {
        props.editBlock(['paragraph', props.data[0], height])
    }

    const closeDialog = () => {
        setHeight(props.data[2])
        document.getElementById('edit-element-dialog').close()
    }

    useEffect(() => {
        if(props.edit == true){
            setHeight(props.data[2])
        }
    }, [])


    return (
        <div className='spacing-component-body'>
            <div className='options'>
                <label>Wysokość: <input type="number" defaultValue={height} max={1000} onChange={(e) => setHeight(e.target.value > 1000 ? 1000 : e.target.value < 0 ? 1 : e.target.value)}></input></label>
                
                <button className='accept' onClick={props.edit == true ? setNewData : setSpacingComponent}><Icon icon="mingcute:check-fill" /></button>
                <button className='delicine' onClick={props.edit == true ? closeDialog : props.clearComponentArray}><Icon icon="octicon:x-16" /></button>
            </div>
            <div className='spacing-input'>
                <div style={{height: `${height}px`}}></div>
            </div>
        </div>
    )
}

export default SpacingComponent