import React from 'react'
import { useNavigate } from 'react-router-dom'
import './singleOffer.css'
import './singleOfferMediaQuery.css'

const SingleOffer = (props) => {
    const navigate = useNavigate()

    return (
        <div className='singleOffer' key={props.id} id={props.id} onClick={(e) => navigate(`/singleOffer?offerId=${e.currentTarget.id}`)}>
            <h2>{props.title}</h2>
            <p>{props.text}</p>
            <div className='offerInfo'>
                <p><span>Stanowiska:</span> {props.positions.map((position) => (position + ', '))}</p>
                <p><span>Technologie:</span> {props.technologies.map((tech) => (tech + ', '))}</p>
            </div>
        </div>
    )
}

export default SingleOffer