import React, { useEffect, useState } from 'react'
import Menu from '../../components/menu/menu'
import './addNewPublicOffer.css'
import './addNewPublicOfferMediaQuery.css'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { addDoc, collection } from 'firebase/firestore'
import { db, auth } from '../../config/firebase'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import MenuCompact from '../../components/menuCompact/menuCompact'


const AddNewPublicOffer = () => {
  const projectsCollection = collection(db, 'publicProjects')

  const navigate = useNavigate()

  useEffect(() => {
    if(!auth?.currentUser?.email){
      navigate("/login?code=8")
    }
  }, [])

  const errorFunction = (content) => {
    toast.error(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const [projectTitle, setProjectTitle] = useState("")
  const [projectDescription, setProjectDescription] = useState("")
  const [github, setGithub] = useState("")
  const [error, setError] = useState("")
  const [file, setFile] = useState(null)
  const formats = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp']

  const isGithubLink = (link) => {
    if(link.length > 0){
      const githubUrlPattern = /^https:\/\/github\.com/
      return githubUrlPattern.test(link)
    }
    else{
      return true
    }
  }

  const fileUpdate = (e) => {
    if(!formats.includes(e.currentTarget.files[0].type)){
      e.target.value = null;
      errorFunction("Nieobsługowany format pliku, wybierz inny plik")
    }
    else if(e.currentTarget.files[0].size > 1048576){
      e.target.value = null;
      errorFunction("Plik jest za duży, maksymalny rozmiar pliku to 1 MB")
    }
    else{
      const selectedFile = e.currentTarget.files[0]
      setFile(selectedFile) 
    }
  }

  const setNewProject = async () => {
    let actualDate = new Date();
    try{
      await addDoc(projectsCollection, {
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        date: `${(Number(actualDate.getYear()) + 1900) + '-' + (actualDate.getMonth() + 1 > 9 ? actualDate.getMonth() + 1 : "0" + Number(actualDate.getMonth() + 1)) + '-' + actualDate.getDate()} ${actualDate.getHours()}:${actualDate.getMinutes()}`,
        title: projectTitle,
        description: projectDescription,
        image: file != null ? file.name : 'empty-project.webp',
        github: github,
        posts: [],
      })

      navigate("/projectsSite?code=1")
    }
    catch(err){
      errorFunction("Błąd dodwania projektu")
    }
  } 

  const createOffer = async () => {
    if(projectTitle.length == 0 || projectDescription.length == 0){
      errorFunction("Tytuł i opis nie mogą być puste")
    }
    else if(projectTitle.length > 500){
      errorFunction("Tytuł nie może przekraczać 500 znaków")
    }
    else if(projectDescription.length > 2000){
      errorFunction("Opis nie może przekraczać 2000 znaków")
    }
    else if(github.length > 200 || !isGithubLink(github)){
      errorFunction("Podany ciąg znaków nie jest linkiem do serwisu github")
    }
    else{
      await setNewProject()

      if(file != null){
        const formData = new FormData()
        formData.append('file', file)
        formData.append('user', auth.currentUser.uid)
    
        fetch('https://codexperience.przybyslawice.kylos.pl/static/php/uploadFile.php', {
          method: 'POST',
          body: formData,
        })
        .then((response) => {console.log('Wysłano plik na serwer', response)})
        .catch((err) => {console.error(err)})
      }      
    }
  }

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  return (
    <section className='add-new-public-offer-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}

      <Link className='back-to-offers' to="/projectsSite"> <Icon icon="material-symbols:arrow-left" /> Powrót do projektów </Link>

      <div className='add-project'>
        <h1>Stwórz nową stronę projektu</h1>
        <p>Stwórz nową stronę swojego projektu, żeby inni mogli go podziwiać. Będziesz mógł publikować na tej stronie posty z informacjami jak idą prace. Będziesz mógł się chwalić innym oraz udostępniać historię pracy nad swoim dziełem.</p>

        <div className='add-project-form'>

          <p className='add-project-error'>{error}</p>
          <input placeholder='Tytuł (max 500 znaków)' onChange={(e) => setProjectTitle(e.target.value)}></input>
          <label for="header-image" className='label-for-file'>{file == null ? "Wybierz zdjęcie" : file.name}</label>
          <input type='file' id="header-image" onChange={(e) => fileUpdate(e)}></input>
          <input placeholder='Github (max 200 znaków)' onChange={(e) => setGithub(e.target.value)}></input>
          <textarea placeholder='Opis (max 2000 znaków)' onChange={(e) => setProjectDescription(e.target.value)}></textarea>

          <button onClick={createOffer}>Stwórz ogłoszenie</button>
        </div>

      </div>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>
      <ToastContainer/>
    </section>
  )
}

export default AddNewPublicOffer