import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainSite from './sites/mainSite/mainSite';
import OfferSite from './sites/offerSite/offerSite'
import ContactSite from './sites/contactSite/contactSite'
import LoginSite from './sites/loginSite/loginSite';
import RegisterSite from './sites/registerSite/registerSite';
import SingleOfferSite from './sites/singleOfferSite/singleOfferSite';
import UserPanel from './sites/userPanel/userPanel';
import AddOfferSite from './sites/addOfferSite/addOfferSite';
import ResponseOfferSite from './sites/responseOfferSite/responseOfferSite';
import ProjectsSite from './sites/projectsSite/projectsSite';
import AddNewPublicOffer from './sites/addNewPublicOffer/addNewPublicOffer';
import SingleProject from './sites/singleProject/singleProject';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainSite />} />
        <Route path='offer' element={<OfferSite />} />
        <Route path='contact' element={<ContactSite />} />
        <Route path='login' element={<LoginSite />} />
        <Route path='register' element={<RegisterSite />} />
        <Route path='singleOffer' element={<SingleOfferSite />} />
        <Route path='userPanel' element={<UserPanel />}></Route>
        <Route path='addOffer' element={<AddOfferSite />}></Route>
        <Route path='responseOffer' element={<ResponseOfferSite />}></Route>
        <Route path='projectsSite' element={<ProjectsSite />}></Route>
        <Route path='addNewPublicProject' element={<AddNewPublicOffer/>} ></Route>
        <Route path='singleProject' element={<SingleProject/>} ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
