import React from 'react'
import AccountInfo from '../AccountInfo/AccountInfo'
import MyOffers from '../MyOffers/MyOffers'
import UserChats from '../UserChats/UserChats'
import Volunteers from '../Volunteers/Volunnteers'
import MyProjects from '../MyProjects/MyProjects'

const UserPanelDetails= (props) => {

  const renderComponents = () => {
    if(props.option === "UserPanelInfo"){
      return <AccountInfo/>
    }
    else if(props.option === "UserPanelOffers"){
      return <MyOffers />
    }
    else if(props.option === "UserPanelProjects"){
      return <MyProjects />
    }
    else if(props.option === "UserPanelChat"){
      return <UserChats />   
    }
    else if(props.option === "UserPanelVolunteers"){
      return <Volunteers />   
    }
  }

  return (
    <>
      {renderComponents()}
    </>
  )
}

export default UserPanelDetails