import React, { useEffect, useState } from 'react'
import './ParagraphComponent.css'
import './ParagraphComponentMediaQuery.css'
import { Icon } from '@iconify/react';
import { Bounce, toast } from 'react-toastify';

const ParagraphComponent = (props) => {
    const [size, setSize] = useState(24)
    const [align, setAlign] = useState('left')
    const [color, setColor] = useState('black')
    const [content, setContent] = useState('')
    const [fontFamily, setFontFamily] = useState('Calibri')
    const [bold, setBold] = useState(false)
    const [italic, setItalic] = useState(false)
    const [underline, setUnderline] = useState(false)

    const errorFunction = (content) => {
        toast.error(content, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        })
    }

    const editParagrapf = (e) => {
        if (e.key == "Enter") {
            e.preventDefault()

            const selection = window.getSelection();
            const text = document.getElementById('content-editable').innerText;
            const anchorOffset = selection.anchorOffset;
            const focusOffset = selection.focusOffset;
            const selectedText = selection.toString();

            let lines = text.split(/[\n\r]+|<br>/)

            if (selectedText === '' &&  focusOffset === lines[lines.length - 1].length) {
                document.execCommand('insertHTML', false, '<br><br>');
            } else {
                document.execCommand('insertHTML', false, '<br>');
            }
        }

        if(e.key == 'b' || e.key == 'B' || e.key == 'u' || e.key == 'U' || e.key == 'i' || e.key == 'I'){
            if(e.ctrlKey){
                e.preventDefault()
            }
        }
    }

    const createParagraphComponent = () => {
        if(content.length === 0){
            props.clearComponentArray()
        }
        else if(content.length > 3000){
            errorFunction("Treść pojedyńczego akapitu nie może przekraczać 3000 znaków")
        }
        else{
            props.setNewParagraph(fontFamily, size, bold, italic, underline, align, color, content)
        }
    }

    const setNewData = () => {
        if(content.length === 0){
            props.clearComponentArray()
        }
        else if(content.length > 3000){
            errorFunction("Treść pojedyńczego akapitu nie może przekraczać 3000 znaków")
        }
        else{
            props.editBlock(['paragraph', props.data[0], fontFamily, size, bold, italic, underline, align, color, content])
        }
    }

    useEffect(() => {
        if(props.edit == true){
            setSize(props.data[3])
            setAlign(props.data[7])
            setColor(props.data[8])
            setContent(props.data[9])
            setFontFamily(props.data[2])
            setBold(props.data[4])
            setItalic(props.data[5])
            setUnderline(props.data[6])
        }
    }, [])

    const closeDialog = () => {
        setSize(props.data[3])
        setAlign(props.data[7])
        setColor(props.data[8])
        setContent(props.data[9])
        setFontFamily(props.data[2])
        setBold(props.data[4])
        setItalic(props.data[5])
        setUnderline(props.data[6])
        document.getElementById('edit-element-dialog').close()
    }


    return (
        <div className='paragraph-component-body'>
            <div className='options'>
                <select value={fontFamily} onChange={(e) => setFontFamily(e.target.value)}>
                    <option value="Calibri">Calibri</option>
                    <option value="Arial">Arial</option>
                    <option value="Wellfleet">Wellfleet</option>
                    <option value="Lemon">Lemon</option>
                    <option value="Poppins">Poppins</option>
                    <option value="Krona One">Krona One</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Oswald">Oswald</option>
                </select>

                <input type="number" value={size} defaultValue={24} onChange={(e) => setSize(e.target.value > 100 ? 100 : e.target.value < 0 ? 1 : e.target.value)}></input>

                <button onClick={() => setBold(bold => !bold)}><Icon icon="octicon:bold-16" /></button>
                <button onClick={() => setItalic(italic => !italic)}><Icon icon="mingcute:italic-fill" /></button>
                <button onClick={() => setUnderline(underline => !underline)}><Icon icon="mingcute:underline-fill" /></button>

                <button onClick={() => setAlign('left')}><Icon icon="mingcute:align-left-fill" /></button>
                <button onClick={() => setAlign('center')}><Icon icon="mingcute:align-center-fill" /></button>
                <button onClick={() => setAlign('right')}><Icon icon="mingcute:align-right-fill" /></button>

                <input type="color" onChange={(e) => setColor(e.target.value)}></input>

                <button className='accept' onClick={props.edit == true ? setNewData : createParagraphComponent}><Icon icon="mingcute:check-fill" /></button>
                <button className='delicine' onClick={props.edit == true ? closeDialog : props.clearComponentArray}><Icon icon="octicon:x-16" /></button>
            </div>
            <p id='content-editable' style={{fontFamily: fontFamily, fontSize: `${size}px`, textAlign: align, color: color, fontWeight: `${bold == true ? 'bold' : 'normal'}`, fontStyle: `${italic == true ? 'italic' : 'normal'}`, textDecoration: `${underline == true ? 'underline' : 'none'}` }} onKeyDown={(e) => editParagrapf(e)} onInput={(e) => { setContent(e.target.innerText) }} className='content' contentEditable="true" suppressContentEditableWarning="true" spellCheck="false">{props.data && props.data[9]}</p>
        </div>
    )
}

export default ParagraphComponent