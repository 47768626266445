import React from 'react'
import './fullWidthArticle.css'
import './fullWidthArticleMediaQuery.css'

const FullWidthArticle = props => {
  return (
    <div className='fullWidthArticle'>
        <h6>{props.title}</h6>
        <hr />
        <p>{props.text}</p>
        <button>Dowiedz się więcej</button>
    </div>
  )
}

export default FullWidthArticle