import React, { useEffect, useRef, useState } from 'react'
import Menu from '../../components/menu/menu'
import './projectsSite.css'
import './projectsSiteMediaQuery.css'
import { auth, db } from '../../config/firebase'
import { getDocs, collection } from 'firebase/firestore'
import { Icon } from '@iconify/react';
import { Link, useNavigate } from 'react-router-dom'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import MenuCompact from '../../components/menuCompact/menuCompact'


const ProjectsSite = () => {
  const [projectsList, setProjectsList] = useState([])
  const [projectName, setProjectName] = useState("")

  const navigate = useNavigate()
  const projectsCollection = collection(db, 'publicProjects')

  const successFunction = (content) => {
    toast.success(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const getProjects = async (name, toFilter = false) => {
    try{
      const data = await getDocs(projectsCollection)
      let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

      let filtered = finalData.filter((project) => {
        return(
          project.title && project.title.toLowerCase().includes(name)
        )
      })

      setProjectsList(filtered)

      if(toFilter){
        setProjectsToLoad([])
        setLoader(true)
      }
    }
    catch(err){
      console.error(err)
    }
  }

  const findProject = (name) => {
    setProjectName(name)
    getProjects(name, true)
  }

  const loadProjectSite = (e) => {
    navigate(`/singleProject?id=${e.currentTarget.id}`)
  }

  useEffect(() => {
    getProjects("")
    const urlParams = new URLSearchParams(window.location.search)
    let code = urlParams.get('code')

    if (code == 1) {
      successFunction("Stworzono projekt")
    }
  }, [])

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])


  /* intersection observer made for lazy loading on website*/

  const [projectsToLoad, setProjectsToLoad] = useState([])
  const projectsRef = useRef()
  const [firstLoad, setFirstLoad] = useState(true)

  const loadProjectsToArray = () => {
    let lengthOfProjects = projectsRef.current.querySelectorAll('.single-project').length;

    projectsList.slice(lengthOfProjects, lengthOfProjects+6).forEach((project, index) => {
      if(projectsList.length > lengthOfProjects + index){
        setProjectsToLoad(prev => [...prev, project])

        if(index === 5){
          setFirstLoad(false)
        }
      }
    })
  };

  useEffect(() => {
    if(projectsRef.current.children[projectsRef.current.children.length - 1] !== undefined){
      projectObserver.observe(projectsRef.current.children[projectsRef.current.children.length - 1])
    }
  }, [firstLoad, projectsToLoad])

  const projectObserver = new IntersectionObserver(entries => {
    const lastCard = entries[0]

    if(lastCard.isIntersecting){
      loadProjectsToArray()
      projectObserver.unobserve(lastCard.target)
    }
  })

  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if(loader == true && projectsList.length != 0){
      loadProjectsToArray()
      setLoader(false)
    }
  }, [projectsList])

  return (
    <section className='projects-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}

      <div className='top-search-bar-projects'>
        <div>
          <input placeholder='Wyszukaj' onChange={(e) => findProject(e.target.value.toLowerCase())}></input>
          <Icon icon="ic:baseline-search" />
        </div>
        <Link to="/addNewPublicProject"><button>Stwórz stronę projektu</button></Link>
      </div>

      <div className='project-box' ref={projectsRef}>
        {projectsToLoad.map((project) => (
          <div className='single-project' id={project.id} key={project.id} onClick={(e) => loadProjectSite(e)}>
            <div className='project-image'>
              <img src={project.image == 'empty-project.jpg' ? '../../static/media/images/empty-project.webp' : `../../static/media/usersImages/${project.authorID}/${project.image}`}/>  
            </div>
            <h2>{project.title}</h2>
            <p>{project.description}</p>
            <p>Ostatnia aktualizacja: {project.date}</p>
          </div>
        ))}
      </div>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>
      <ToastContainer/>
    </section>
  )
}

export default ProjectsSite