import React, { useEffect, useRef, useState } from 'react'
import Menu from '../../components/menu/menu'
import './singleProject.css'
import './singleProjectMediaQuery.css'
import { arrayUnion, collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import { auth, db } from '../../config/firebase'
import { Icon } from '@iconify/react';
import ChooseComponent from './components/ChooseComponent/ChooseComponent'
import HeadingComponent from './components/HeadingComponent/HeadingComponent'
import ParagraphComponent from './components/ParagraphComponent/ParagraphComponent'
import ImageComponent from './components/ImageComponent/ImageComponent'
import DelimiterComponent from './components/DelimiterComponent/DelimiterComponent'
import SpacingComponent from './components/SpacingComponent/SpacingComponent'
import ListsComponent from './components/ListsComponent/ListsComponent'
import defaultImage from '../../media/images/default-post.webp'
import LinkComponent from './components/LinkComponent/LinkComponent'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import MenuCompact from '../../components/menuCompact/menuCompact'

const SingleProject = () => {
  const [project, setProject] = useState([])
  const projectCollection = collection(db, 'publicProjects')
  const containerRef = useRef(null)
  const [showComponent, setShowComponent] = useState(null)
  const [componentArray, setComponentArray] = useState([])
  const [isActiveBlock, setIsActiveBlock] = useState(false)
  const [finalBLocks, setFinalBlocks] = useState([])
  const [posts, setPosts] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)
  const [insertMode, setInserMode] = useState(false)
  const [permEditMode, setPermEditMode] = useState(false)
  const [actualPostID, setActualPostID] = useState("")

  const [newTitle, setNewTitle] = useState("")
  const [newDescription, setNewDescription] = useState("")
  const [newGithub, setNewGithub] = useState("")

  const [postsToLoad, setPostsToLoad] = useState([])

  const [postTitle, setPostTitle] = useState("")

  const sortByDate = (a, b) => {
    if (new Date(a.date) > new Date(b.date)) {
        return -1;
    }
    if (new Date(a.date) < new Date(b.date)) {
        return 1;
    }
    return 0;
  }

  const loadProject = async () => {
    const data = await getDocs(projectCollection)
    let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))


    const urlParams = new URLSearchParams(window.location.search)
    let projectID = urlParams.get('id')

    let toSave = finalData.filter((project) => {
      return (
        project.id && project.id.includes(projectID)
      )
    })

    setProject(toSave)
    setPosts(toSave[0].posts.sort(sortByDate))

    setPostsToLoad([])
    setLoader(true)

  }

  const successFunction = (content) => {
    toast.success(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const errorFunction = (content) => {
    toast.error(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const showModal = () => {
    document.getElementById('add-new-post-dialog').showModal()
  }

  const closeModal = () => {
    document.getElementById('add-new-post-dialog').close()
    setPermEditMode(false)
    setFinalBlocks([])
    setPostTitle("")
  }

  useEffect(() => {
    loadProject()
  }, [])

  const addContextMenu = () => {
    if (isActiveBlock == false && editMode != true) {
      setShowComponent(true) //adding choose block component
      setInserMode(true) //setting insert mode to true - if true user is not able to add post
    }
    else {
      errorFunction("Błąd")
    }
  }

  const setNewBlock = (componentType) => {
    setIsActiveBlock(true)
    setShowComponent(false) //removing choose block component
    setComponentArray(prevArray => { //setting new block in array (from choose block component)
      const index = prevArray.length > 0 ? prevArray[prevArray.length - 1][0] + 1 : 0;
      return [...prevArray, [index, componentType]];
    });
  }

  const setNewHeading = (heading, align, color, content, size) => {
    setComponentArray([])


    const container = containerRef.current
    const addblock = document.getElementById('add-btn')

    setFinalBlocks(prevArray => {
      const index = prevArray.length > 0 ? prevArray[prevArray.length - 1][0] + 1 : 0;
      return [...prevArray, [index, 'heading', heading, align, color, content, size]];
    });

    setIsActiveBlock(false)
    setInserMode(false)

  }

  const setNewImage = (width, height, align, src) => {
    setComponentArray([])


    const container = containerRef.current
    const addblock = document.getElementById('add-btn')

    setFinalBlocks(prevArray => {
      const index = prevArray.length > 0 ? prevArray[prevArray.length - 1][0] + 1 : 0;
      return [...prevArray, [index, 'image', width, height, align, src]];
    });

    setIsActiveBlock(false)
    setInserMode(false)

  }

  const setNewDelimiter = (style, width, align, color) => {
    setComponentArray([])

    setFinalBlocks(prevArray => {
      const index = prevArray.length > 0 ? prevArray[prevArray.length - 1][0] + 1 : 0;
      return [...prevArray, [index, 'delimiter', style, width, align, color]];
    });

    setIsActiveBlock(false)
    setInserMode(false)
  }

  const setNewSpacing = (height) => {
    setComponentArray([])

    setFinalBlocks(prevArray => {
      const index = prevArray.length > 0 ? prevArray[prevArray.length - 1][0] + 1 : 0;
      return [...prevArray, [index, 'spacing', height]];
    });

    setIsActiveBlock(false)
    setInserMode(false)
  }

  const setNewLink = (link, title, color, fontSize) => {
    setComponentArray([])

    setFinalBlocks(prevArray => {
      const index = prevArray.length > 0 ? prevArray[prevArray.length - 1][0] + 1 : 0;
      return [...prevArray, [index, 'link', link, title, color, fontSize]];
    });

    setIsActiveBlock(false)
    setInserMode(false)
  }

  const setNewList = (type, bold, italic, underline, align, color, content) => {
    setComponentArray([])

    setFinalBlocks(prevArray => {
      const index = prevArray.length > 0 ? prevArray[prevArray.length - 1][0] + 1 : 0;
      return [...prevArray, [index, 'list', type, bold, italic, underline, align, color, content]];
    });

    setIsActiveBlock(false)
    setInserMode(false)
  }

  const setNewParagraph = (fontFamily, fontSize, bold, italic, underline, align, color, content) => {
    setComponentArray([])

    setFinalBlocks(prevArray => {
      const index = prevArray.length > 0 ? prevArray[prevArray.length - 1][0] + 1 : 0;
      return [...prevArray, [index, 'paragraph', fontFamily, fontSize, bold, italic, underline, align, color, content]];
    });

    setIsActiveBlock(false)
    setInserMode(false)
  }

  const [file, setFile] = useState(null)
  const formats = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp']

  const fileUpdate = (e) => {
    if (!formats.includes(e.currentTarget.files[0].type)) {
      e.target.value = null;
      console.error("Nieobsługowany format pliku, wybierz inny plik")
    }
    else if (e.currentTarget.files[0].size > 1048576) {
      e.target.value = null;
      console.error("Plik jest za duży, maksymalny rozmiar pliku to 1 MB")
    }
    else {
      const selectedFile = e.currentTarget.files[0]
      setFile(selectedFile)
    }
  }

  const setNewPost = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    let projectID = urlParams.get('id')
    let actualDate = new Date();
    const postContent = containerRef.current.innerHTML.slice(0, -110)

    let id = ""
    let letterArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for(let i = 0; i<10; i++){
      id += letterArray[parseInt(Math.random() * letterArray.length)]
    }

    const postCollection = doc(db, "publicProjects", projectID)

    await updateDoc(postCollection, {
      date: `${(Number(actualDate.getYear()) + 1900) + '-' + (actualDate.getMonth() + 1 > 9 ? actualDate.getMonth() + 1 : "0" + Number(actualDate.getMonth() + 1)) + '-' + actualDate.getDate()} ${actualDate.getHours()}:${actualDate.getMinutes()}`,
      posts: arrayUnion({id: id, array: JSON.stringify(finalBLocks), title: postTitle, image: file != null ? file.name : 'empty', postContent, date: `${(Number(actualDate.getYear()) + 1900) + '-' + (actualDate.getMonth() + 1 > 9 ? actualDate.getMonth() + 1 : "0" + Number(actualDate.getMonth() + 1)) + '-' + actualDate.getDate()} ${actualDate.getHours()}:${actualDate.getMinutes()}` })
    })

    
  }

  const addNewPost = async () => {
    if (postTitle.length == 0 || containerRef.current.children.length == 1) {
      errorFunction("Tytuł oraz treść nie mogą być puste")
    }
    else if(postTitle.length > 200){
      errorFunction("Tytuł może zawierać maksymalnie 200 znaków")
    }
    else if(insertMode === true || editMode == true || deleteMode == true){
      errorFunction("Podczas wstawiania wpisu żadne okno edycyjne nie może być aktywne")
    }
    else {
      await setNewPost()

      if (file != null) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('user', auth.currentUser.uid)

        fetch('https://codexperience.przybyslawice.kylos.pl/static/php/uploadFile.php', {
          method: 'POST',
          body: formData,
        })
          .then((response) => { console.log('Wysłano plik na serwer', response) })
          .catch((err) => { console.error(err) })
      }

      loadProject()
      closeModal()

      document.getElementById('post-title-input').value = ""
      
      successFunction("Wstawiono wpis")
    }
  }

  const showPostsContent = (e) => {
    if (e.target.parentElement.querySelector('.post-content').classList.contains('active')) {
      e.target.parentElement.querySelector('.post-content').classList.remove('active')
      e.target.innerHTML = 'Zobacz więcej'
    }
    else {
      e.target.parentElement.querySelector('.post-content').classList.add('active')
      e.target.innerHTML = 'Zobacz mniej'
    }
  }

  const [editModalComponent, setEditModalComponent] = useState([])

  const editContent = (e) => {
    e.preventDefault()
    let index = Array.prototype.indexOf.call(e.currentTarget.parentElement.children, e.currentTarget)

    document.getElementById('edit-element-dialog').showModal()
    setEditModalComponent([...finalBLocks[index], index])
  }

  const editBlock = (data) => {
    if(data[0] == 'heading'){
      let helpArray = [...finalBLocks]

      helpArray[data[1]][2] = data[2]
      helpArray[data[1]][3] = data[3]
      helpArray[data[1]][4] = data[4]
      helpArray[data[1]][5] = data[5]
      helpArray[data[1]][6] = data[6]

      document.getElementById('edit-element-dialog').close()
      setFinalBlocks(helpArray)
    }
    else if(data[0] == 'list'){
      let helpArray = [...finalBLocks]
      
      helpArray[data[1]][2] = data[2]
      helpArray[data[1]][3] = data[3]
      helpArray[data[1]][4] = data[4]
      helpArray[data[1]][5] = data[5]
      helpArray[data[1]][6] = data[6]
      helpArray[data[1]][7] = data[7]
      helpArray[data[1]][8] = data[8]
      
      document.getElementById('edit-element-dialog').close()
      setFinalBlocks(helpArray)
    }
    else if(data[0] == 'paragraph'){
      let helpArray = [...finalBLocks]
      
      helpArray[data[1]][2] = data[2]
      helpArray[data[1]][3] = data[3]
      helpArray[data[1]][4] = data[4]
      helpArray[data[1]][5] = data[5]
      helpArray[data[1]][6] = data[6]
      helpArray[data[1]][7] = data[7]
      helpArray[data[1]][8] = data[8]
      helpArray[data[1]][9] = data[9]
      
      document.getElementById('edit-element-dialog').close()
      setFinalBlocks(helpArray)
    }
    else if(data[0] == 'spacing'){
      let helpArray = [...finalBLocks]
      
      helpArray[data[1]][2] = data[2]
      
      document.getElementById('edit-element-dialog').close()
      setFinalBlocks(helpArray)
    }
    else if(data[0] == 'delimiter'){
      let helpArray = [...finalBLocks]
      
      helpArray[data[1]][2] = data[2]
      helpArray[data[1]][3] = data[3]
      helpArray[data[1]][4] = data[4]
      helpArray[data[1]][5] = data[5]
      
      document.getElementById('edit-element-dialog').close()
      setFinalBlocks(helpArray)
    }
    else if(data[0] == 'link'){
      let helpArray = [...finalBLocks]
      
      helpArray[data[1]][2] = data[2]
      helpArray[data[1]][3] = data[3]
      helpArray[data[1]][4] = data[4]
      helpArray[data[1]][5] = data[5]
      
      document.getElementById('edit-element-dialog').close()
      setFinalBlocks(helpArray)
    }
    else if(data[0] == 'image'){
      let helpArray = [...finalBLocks]
      
      helpArray[data[1]][2] = data[2]
      helpArray[data[1]][3] = data[3]
      helpArray[data[1]][4] = data[4]
      
      document.getElementById('edit-element-dialog').close()
      setFinalBlocks(helpArray)
    }
  }

  const clearComponentArray = () => {
    setComponentArray([])
    setIsActiveBlock(false)
    setInserMode(false)
  }

  const deleteBlock = (e) => {
    e.preventDefault()
    let index = Array.prototype.indexOf.call(e.currentTarget.parentElement.children, e.currentTarget)

    let helpArray = [...finalBLocks]
    helpArray.splice(index, 1)
    setFinalBlocks(helpArray)
  }

  const selectAction = (e) => {
    if(editMode == true && deleteMode != true){
      editContent(e)
    }
    else if(editMode != true && deleteMode == true){
      deleteBlock(e)
    }
  }

  const editPost = (e) => {
    let test = (posts.filter((post) => {
      return (
        post.id && post.id.includes(e.target.parentElement.id)
      )
    }))

    setFinalBlocks([...JSON.parse(test[0].array)])
    setPostTitle(test[0].title)
    setPermEditMode(true)
    setActualPostID(e.target.parentElement.id)
    document.getElementById('post-title-input').value = test[0].title

    showModal()

  }

  const insertEditedData = async () => {
    if (postTitle.length == 0 || containerRef.current.children.length == 1) {
      errorFunction("Tytuł oraz treść nie mogą być puste")
    }
    else if(postTitle.length > 200){
      errorFunction("Tytuł może zawierać maksymalnie 200 znaków")
    }
    else if(insertMode === true || editMode == true || deleteMode == true){
      errorFunction("Podczas wstawiania wpisu żadne okno edycyjne ani akcja nie mogą być aktywne")
    }
    else{
      const urlParams = new URLSearchParams(window.location.search)
      let projectID = urlParams.get('id')
      let actualDate = new Date();
      const postContent = containerRef.current.innerHTML.slice(0, -110)
  
      let postID = posts.findIndex(post => post.id == actualPostID)
      let postsArray = [...posts]
      postsArray[postID].title = postTitle
      postsArray[postID].postContent = postContent
      postsArray[postID].array = JSON.stringify(finalBLocks)
      
      const postCollection = doc(db, "publicProjects", projectID)
  
      if(postTitle.length > 200 || postTitle.length <= 0){
        errorFunction("Tytuł ma nieodpowiednią długość")
      }
      else{
        await updateDoc(postCollection, {
          date: `${(Number(actualDate.getYear()) + 1900) + '-' + (actualDate.getMonth() + 1 > 9 ? actualDate.getMonth() + 1 : "0" + Number(actualDate.getMonth() + 1)) + '-' + actualDate.getDate()} ${actualDate.getHours()}:${actualDate.getMinutes()}`,
          posts: postsArray
        })
    
        closeModal()
        successFunction("Edytowano wpis")
      }
    }
  }

  const deletePost = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    let projectID = urlParams.get('id')
    let actualDate = new Date();

    let postsArray = [...posts]
    postsArray.splice(actualPostID, 1)
    
    const postCollection = doc(db, "publicProjects", projectID)

    await updateDoc(postCollection, {
      date: `${(Number(actualDate.getYear()) + 1900) + '-' + (actualDate.getMonth() + 1 > 9 ? actualDate.getMonth() + 1 : "0" + Number(actualDate.getMonth() + 1)) + '-' + actualDate.getDate()} ${actualDate.getHours()}:${actualDate.getMinutes()}`,
      posts: postsArray
    })

    closeModal()
    loadProject()

    successFunction("Usunięto wpis")
  }

  const headerTitleRef = useRef()
  const headerGithubRef = useRef()
  const headerDescRef = useRef()

  const showEditHeaderModal = () => {
    document.getElementById('edit-project-header').showModal()
    document.getElementById('edit-project-header').style.display = 'flex'
    setNewTitle(headerTitleRef.current.value)
    setNewGithub(headerGithubRef.current.value)
    setNewDescription(headerDescRef.current.value)
  }

  const closeEditHeaderModal = () => {
    document.getElementById('edit-project-header').close()
    document.getElementById('edit-project-header').style.display = 'none'
  }

  const isGithubLink = (link) => {
    if(link.length > 0){
      const githubUrlPattern = /^https:\/\/github\.com/
      return githubUrlPattern.test(link)
    }
    else{
      return true
    }
  }

  const editHeaderData = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    let projectID = urlParams.get('id')
    const postCollection = doc(db, "publicProjects", projectID)

    if(newTitle.length > 500){
      errorFunction("Tytuł nie może zawierać więcej niż 500 znaków")
    }
    else if(newGithub.length > 200 || !isGithubLink(newGithub)){
      errorFunction("Podany ciąg znaków nie jest linkiem do serwisu github")
    }
    else if(newDescription.length > 2000 ){
      errorFunction("Opis nie może zawierać więcej niż 2000 znaków")
    }
    else{
      await updateDoc(postCollection, {
        title: newTitle,
        description: newDescription,
        github: newGithub, 
      })
  
      loadProject()
      closeEditHeaderModal()
  
      successFunction("Edytowano nagłówek projektu")
    }
  }

  useEffect(() => {
    if(editMode == true || deleteMode == true){
      let array = [...document.querySelectorAll('.post-wyswig > *')].slice(0, -2)
      array.forEach((element) => element.classList.add('editable'))

    }
    else{
      let array = [...document.querySelectorAll('.post-wyswig > *')].slice(0, -2)
      array.forEach((element) => element.classList.remove('editable'))
    }
  }, [editMode, deleteMode])

  const closeChooseComponent = () => {
    setInserMode(false)
    setShowComponent(false)
  }

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  /* intersection observer made for lazy loading on website*/

  
  const postsRef = useRef()
  const [firstLoad, setFirstLoad] = useState(true)

  const loadPostsToArray = () => {
    let lengthOfPosts = postsRef.current.querySelectorAll('.single-post').length;

    posts.slice(lengthOfPosts, lengthOfPosts+5).forEach((post, index) => {
      if(posts.length > lengthOfPosts + index){
        setPostsToLoad(prev => [...prev, post])

        if(index === 4){
          setFirstLoad(false)
        }
      }
    })
  };

  useEffect(() => {
    postsObserver.observe(postsRef.current.children[postsRef.current.children.length - 1])
  }, [firstLoad, postsToLoad])

  const postsObserver = new IntersectionObserver(entries => {
    const lastCard = entries[0]

    if(lastCard.isIntersecting){
      loadPostsToArray()
      postsObserver.unobserve(lastCard.target)
    }
  })

  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if(loader == true && posts.length != 0){
      loadPostsToArray()
      setLoader(false)
    }
  }, [posts])


  return (
    <section className='single-project-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}

      <main className='single-project-main' ref={postsRef}>
        {project[0]?.authorID == auth?.currentUser?.uid && (
          <div className='add-new-post'>
            <button onClick={showModal}>Dodaj nowy wpis</button>
          </div>
        )}

        <div className='project-header' style={{ backgroundImage: `url(../../static/media/usersImages/${project[0]?.authorID}/${project[0]?.image})` }}>
          {project[0]?.authorID == auth?.currentUser?.uid && (
            <p className='edit-header' onClick={showEditHeaderModal}>Edytuj nagłówek</p>
          )}
          <h2>{project[0]?.title}</h2>
          <p>{project[0]?.description}</p>
          
          {project[0]?.github != "" && (
            <div className='project-github'>
              <Icon icon="mdi:github" />
              <a href={project[0]?.github}>Link do projektu w serwisie Github</a>
            </div>
          )}
          
          <div className='footer-of-project-header'>
            <p>Autor: {project[0]?.author} </p>
            <p>Data ostatniej aktualizacji: {project[0]?.date}</p>
          </div>
        </div>

        {postsToLoad.map((post) => (
          <div className='single-post' key={post.id} id={post.id}>
            {
              project[0]?.authorID == auth?.currentUser?.uid && (
                <button className='edit-post' onClick={(e) => editPost(e)}>Edytuj post</button>
              )
            }

            <p>{post.date}</p>
            <h3>{post.title}</h3>
            <div className='post-image'>
              <img src={post.image == 'empty' ? defaultImage : `../../static/media/usersImages/${project[0]?.authorID}/${post.image}`} />
            </div>
            <div className='post-content' dangerouslySetInnerHTML={{ __html: post.postContent }}></div>
            <p className='see-more' onClick={(e) => showPostsContent(e)}>Zobacz więcej</p>
          </div>
        ))}
      </main>

      <dialog id='add-new-post-dialog'>
        <div className='post-dialog-top-bar'>
          <p><span>C</span>od<span>eX</span>perience</p>
          <Icon icon="ph:x-bold" onClick={closeModal} />
        </div>
        <h3>{permEditMode == true ? "Edytuj wpis" : "Dodaj nowy wpis"}</h3>
        <div className='post-form'>
          <div className='post-form-input-sets'>
            <div className={`post-title ${permEditMode == true && 'full-width'}`}>
              <label for='post-title-input'>Tytuł</label>
              <input type='text' id='post-title-input' onChange={(e) => setPostTitle(e.target.value)}></input>
            </div>
            {permEditMode == false &&
            <div className='post-image'>
              <p>Zdjęcie wpisu</p>
              <label for='post-image-input'>{file == null ? "Wybierz zdjęcie" : file.name}</label>
              <input type='file' id="post-image-input" onChange={(e) => fileUpdate(e)}></input>
            </div>
            }
          </div>

          <div className='post-wyswig' ref={containerRef}>

            {finalBLocks.map(block => (
              block[1] == 'heading' ? 
                block[2] == "1" ? <h1 onClick={(e) => selectAction(e)} style={{ textAlign: block[3], color: block[4], fontSize: `${block[6]}rem` }}>{block[5]}</h1> :
                block[2] == "2" ? <h2 onClick={(e) => selectAction(e)} style={{ textAlign: block[3], color: block[4], fontSize: `${block[6]}rem` }}>{block[5]}</h2> :
                block[2] == "3" ? <h3 onClick={(e) => selectAction(e)} style={{ textAlign: block[3], color: block[4], fontSize: `${block[6]}rem` }}>{block[5]}</h3> :
                block[2] == "4" ? <h4 onClick={(e) => selectAction(e)} style={{ textAlign: block[3], color: block[4], fontSize: `${block[6]}rem` }}>{block[5]}</h4> :
                block[2] == "5" ? <h5 onClick={(e) => selectAction(e)} style={{ textAlign: block[3], color: block[4], fontSize: `${block[6]}rem` }}>{block[5]}</h5> :
                block[2] == "6" ? <h6 onClick={(e) => selectAction(e)} style={{ textAlign: block[3], color: block[4], fontSize: `${block[6]}rem` }}>{block[5]}</h6> : <></> :
              block[1] == 'image' ? <div onClick={(e) => selectAction(e)} className='image-in-post' style={{ justifyContent: block[4] }}><img width={block[2]} height={block[3]} src={`../../static/media/usersImages/${auth.currentUser.uid}/${block[5]}`} /></div> :
              block[1] == 'delimiter' ? <div onClick={(e) => selectAction(e)} className='delimiter-in-post' style={{ justifyContent: block[4] }}><hr style={{ width: `${block[3]}%`, border: `1px ${block[2]} ${block[5]}` }} /></div> :
              block[1] == 'spacing' ? <div onClick={(e) => selectAction(e)} style={{ height: `${block[2]}px` }}></div> :
              block[1] == 'list' ? block[2] == 'ul' ? <div onClick={(e) => selectAction(e)} className='list-in-post' style={{ justifyContent: block[6] }}><ul style={{ color: block[7], fontWeight: `${block[3] == true ? 'bold' : 'normal'}`, fontStyle: `${block[4] == true ? 'italic' : 'normal'}`, textDecoration: `${block[5] == true ? 'underline' : 'none'}` }}>{block[8].map(li => <li>{li}</li>)}</ul></div> : <div className='list-in-post' onClick={(e) => selectAction(e)} style={{ justifyContent: block[6] }}><ol style={{ color: block[7], fontWeight: `${block[3] == true ? 'bold' : 'normal'}`, fontStyle: `${block[4] == true ? 'italic' : 'normal'}`, textDecoration: `${block[5] == true ? 'underline' : 'none'}` }}>{block[8].map(li => <li>{li}</li>)}</ol></div> :
              block[1] == 'paragraph' ? <p onClick={(e) => selectAction(e)} className='paragraph-in-post' style={{ textAlign: block[7], fontFamily: block[2], fontSize: `${block[3]}px`, fontWeight: `${block[4] == true ? 'bold' : 'normal'}`, fontStyle: `${block[5] == true ? 'italic' : 'normal'}`, textDecoration: `${block[6] == true ? 'underline' : 'none'}`, color: block[8] }}>{block[9]}</p> :
              block[1] == 'link' ? <a onClick={(e) => selectAction(e)} href={block[2]} style={{ color: block[4], fontSize: `${block[5]}px` }}>{block[3]}</a> : <></>
            ))}

            {componentArray.map(component => (
              component[1] == 'heading' ? <HeadingComponent setNewHeading={setNewHeading} edit={false} clearComponentArray={clearComponentArray}/> :
                component[1] == 'image' ? <ImageComponent setNewImage={setNewImage} edit={false} clearComponentArray={clearComponentArray}/> :
                  component[1] == 'list' ? <ListsComponent setNewList={setNewList} edit={false} clearComponentArray={clearComponentArray}/> :
                    component[1] == 'paragraph' ? <ParagraphComponent setNewParagraph={setNewParagraph} edit={false} clearComponentArray={clearComponentArray}/> :
                      component[1] == 'spacing' ? <SpacingComponent setNewSpacing={setNewSpacing} edit={false} clearComponentArray={clearComponentArray}/> :
                        component[1] == 'separator' ? <DelimiterComponent setNewDelimiter={setNewDelimiter} edit={false} clearComponentArray={clearComponentArray}/> :
                          component[1] == 'link' ? <LinkComponent setNewLink={setNewLink} edit={false} clearComponentArray={clearComponentArray}/> : <></>
            ))}

            {showComponent == true && <ChooseComponent closeChooseComponent={closeChooseComponent} setNewBlock={setNewBlock} />}
            <button id='add-btn' onClick={addContextMenu}>+</button>
            <button id='edit-btn' onClick={() => deleteMode != true && setEditMode(editMode => !editMode)}>{editMode == false ? "Edytuj" : "Zakończ edycję"}</button>
            <button id='delete-btn' onClick={() => editMode != true && setDeleteMode(deleteMode => !deleteMode)}>{deleteMode == false ? "Usuń blok" : "Zakończ usuwanie"}</button>
          </div>
        </div>
          <button className='add-new-post-btn' onClick={permEditMode == true ? insertEditedData : addNewPost}>{permEditMode == true ? "Edytuj wpis" : "Dodaj wpis"}</button>
          {permEditMode == true && <button className='delete-post-btn' onClick={deletePost}>Usuń wpis</button>}
      </dialog>

      <dialog id='edit-element-dialog'>
        <h3>Edytuj blok</h3>
        <div className='edit-area'>
          {editModalComponent[1] == 'heading' ? <HeadingComponent setNewHeading={setNewHeading} edit={true} data={editModalComponent} editBlock={editBlock}/> :
           editModalComponent[1] == 'list' ? <ListsComponent setNewHeading={setNewList} edit={true} data={editModalComponent} editBlock={editBlock}/> : 
           editModalComponent[1] == 'paragraph' ? <ParagraphComponent setNewHeading={setNewList} edit={true} data={editModalComponent} editBlock={editBlock}/> : 
           editModalComponent[1] == 'spacing' ? <SpacingComponent setNewHeading={setNewList} edit={true} data={editModalComponent} editBlock={editBlock}/> : 
           editModalComponent[1] == 'delimiter' ? <DelimiterComponent setNewHeading={setNewList} edit={true} data={editModalComponent} editBlock={editBlock}/> : 
           editModalComponent[1] == 'link' ? <LinkComponent setNewHeading={setNewList} edit={true} data={editModalComponent} editBlock={editBlock}/> : 
           editModalComponent[1] == 'image' ? <ImageComponent setNewHeading={setNewList} edit={true} data={editModalComponent} editBlock={editBlock}/> : <></>}
        </div>
      </dialog>

      <dialog id="edit-project-header">
        <div className='post-dialog-top-bar'>
          <p><span>C</span>od<span>eX</span>perience</p>
          <Icon icon="ph:x-bold" onClick={closeEditHeaderModal} />
        </div>
        <h3>Edytuj nagłówek</h3>
        <input placeholder='Tytuł' ref={headerTitleRef} onChange={(e) => setNewTitle(e.target.value)} defaultValue={project[0]?.title}></input>
        <input placeholder='Github - link do projektu' ref={headerGithubRef} onChange={(e) => setNewGithub(e.target.value)} defaultValue={project[0]?.github}></input>
        <textarea placeholder='Opis' ref={headerDescRef} onChange={(e) => setNewDescription(e.target.value)} defaultValue={project[0]?.description}></textarea>
        <button onClick={editHeaderData}>Edytuj dane projektu</button>
      </dialog>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>
      <ToastContainer/>
    </section>
  )
}

export default SingleProject