import React, { useEffect, useState } from 'react'
import { db, auth, db_realtime } from '../../../../config/firebase'
import { getDocs, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import './Volunteers.css'
import './VolunteersMediaQuery.css'
import { Navigate, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import '../../userPanel.js'
import { onValue, ref, set } from 'firebase/database'
import { Bounce, ToastContainer, toast } from 'react-toastify'


//TODO: przy pierwszym set notification nie moze odczytać usera...

const Volunteers = () => {
    const volunteersCollection = collection(db, 'volunteers') //firebase collection - volunteers
    const [volunteers, setVolunteers] = useState([])
    const [buttons, setButtons] = useState(false) //state to decide if volunteer is accepted and what type of button you must display
    const [singleVol, setSingleVol] = useState({})
    // const [userUID, setUserUID] = useState('')
    const [notificationIndex, setNewNotificationIndex] = useState(0)
    
    let date = new Date()
    const navigate = useNavigate()
    
    
    const successFunction = (content) => {
        toast.success(content, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        })
    }
    
    const errorFunction = (content) => {
        toast.error(content, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        })
    }
 
    const setNewNotification = async (userUID) => {
        if(userUID != undefined && userUID != ''){
            set(ref(db_realtime, `/notifications/${userUID}/nt${(Number(notificationIndex) + Number(1))}`), {
                date: date.getDate() + '.' + (date.getMonth() + 1 > 0 ? date.getMonth() + 1 : "0" + date.getMonth() + 1) + '.' + (Number(date.getYear()) + 1900),
                type: 'acceptVolunteer',
                text: 'Twoje zgłoszenie zostało zaakceptowane - ogłoszeniodawca powinien się z Tobą wkrótce skontaktować',
                index: Number(notificationIndex) + 1,
                status: 'oczekujace',
                category: "Zgłoszenie",
            })
            

            navigate(`/userPanel?status=1&user=${userUID}`, {
                state: {
                    code: "UserPanelChat",
                }
            })
        }
    }

    const setNotificationIndex = async (userUID) => {
        if(userUID != undefined && userUID != ''){
            const nt = ref(db_realtime, `/notifications/${userUID}`)
    
            onValue(nt, (snapshot) => {
                if(snapshot.val() != null){
                    let notifications = Object.values(snapshot.val());
                    let indexArray = []

                    if(notifications.length > 0){
                        notifications.forEach((nt) => {
                            indexArray.push(nt.index)
                        })
                    }
                    else{
                        setNewNotificationIndex(0)
                    }
                    
                    let firstIndex = 0;

                    indexArray.forEach((index) => {
                        if(index > firstIndex) firstIndex = index
                    })

                    setNewNotificationIndex(firstIndex)
  
                }
                else{
                    setNewNotificationIndex(0)
                }
            })
        }
    }

    const closeVolunteerWindow = async () => {
        document.getElementById('volunteer-dialog').close();
    }

    const getVolunteers = async () => { //async function to load volunteers
        const data = await getDocs(volunteersCollection)

        let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

        let volunteers = finalData.filter((volunteer) => {
            return (
                volunteer.author && volunteer.author.includes(auth.currentUser.uid)
            )
        })

        setVolunteers(volunteers)
    }

    const rejectVolunteer = async () => {
        let idToDel = document.getElementById('id-for-delete').innerText

        try {
            await deleteDoc(doc(db, "volunteers", idToDel))
            await getVolunteers()
            await closeVolunteerWindow()
            // await checkUserUID(singleVol[0].user)
            successFunction("Usunięto zgłoszenie") 
        }
        catch (err) {
            errorFunction("Błąd aktualizacji stanu")
        }
    }

    const moveToChat = async () => {
        await updateDoc(doc(db, 'volunteers', `${singleVol[0].id}`), {
            status: false,
        })
        await setNewNotification(singleVol[0].userUID) 
    }

    const showVolunteer = (e) => {
        document.getElementById('volunteer-dialog').showModal();

        let vol = volunteers.filter((vol) => {
            return (
                vol.id && vol.id.includes(e.currentTarget.id)
            )
        })

        setSingleVol(vol)
        setNotificationIndex(vol[0].userUID)

        document.getElementById('id-for-delete').innerText = vol[0].id
        document.getElementById('vol-user').innerHTML = `<span>Użytkownik: </span> ${vol[0].user}`
        document.getElementById('vol-role').innerHTML = `<span>Rola: </span> ${vol[0].role}`
        document.getElementById('vol-title').innerHTML = `<span>Projekt: </span> ${vol[0].projectTitle}`
        document.getElementById('vol-desc').innerHTML = `<span>Treść: </span> ${vol[0].text}`


        if(vol[0].status === false){
            setButtons(true)
        }
        else{
            setButtons(false)
        }
    }

    useEffect(() => {
        if (!auth?.currentUser?.email) {
            navigate("/offer")
        }
        getVolunteers()

    }, [])


    return (
        <div className='my-volunteers-container'>
            <h3>Zgłoszenia</h3>
            <p>Przeglądaj zgłoszenia użytkowników do twoich projektów. Możesz także nprzechodzić bezpośrednio do rozmowy ze zgłaszającym się użytkownikiem.</p>
            <div className='my-volunteers'>
                {
                    volunteers.length > 0 ? volunteers.map((vol) => (
                        <div key={vol.id} id={vol.id} data-author={vol.userUID} onClick={(e) => showVolunteer(e)}>
                            <div className='title-container'>
                                <h4><span>{vol.role}</span> - zgłoszenie użytkownika {vol.user}</h4>
                                <p>Data zgłoszenia: {vol.responseDate}</p>
                            </div>
                            <p>Projekt: "{vol.projectTitle}"</p>
                            <p className='volunteer-status'>Ststus: {vol.status === false ? <span>Zaakceptowano</span> : <span>Oczekujące</span>}</p>
                        </div>
                    ))
                        :
                        <p className='blank-volunteers'>Brak zgłoszeń</p>
                }
            </div>

            <dialog className="volunteer-dialog" id='volunteer-dialog'>
                <div className='volunteer-dialog-top-bar'>
                    <p><span>C</span>od<span>eX</span>perience</p>
                    <Icon icon="ph:x-bold" onClick={() => closeVolunteerWindow()} />
                </div>
                <h4>Zgłoszenie do projektu</h4>
                <div className='volunteer-details'>
                    <p id="id-for-delete"></p>
                    <p className='vol-user' id='vol-user'></p>
                    <p className='vol-role' id='vol-role'></p>
                    <p className='vol-title' id='vol-title'></p>
                    <p className='vol-desc' id='vol-desc'></p>
                </div>
                <div className='volunteer-buttons'>
                    {
                        buttons === false ? 
                        <>
                            <button onClick={() => rejectVolunteer()}>Odrzuć zgłoszenie</button>
                            <button onClick={() => { moveToChat() }}>Zaakceptuj i przejdź do czatu</button>
                        </>
                        :
                        <button onClick={() => rejectVolunteer()}>Usuń zgłoszenie</button>
                    }
                </div>
            </dialog>
            <ToastContainer/>
        </div>
    )
}

export default Volunteers