import React from 'react'
import './contactForm.css'

const ContactForm = (props) => {
  return (
    <div className='form'>
        <input ref={props.emailRef} type="email" placeholder='Email' onChange={(e) => props.setEmail(e.target.value)}></input>
        <input ref={props.topicRef} placeholder='Temat' onChange={(e) => props.setTitle(e.target.value)}></input>
        <textarea ref={props.msgRef} placeholder='Wiadomość' onChange={(e) => props.setMessage(e.target.value)}></textarea>   
        <input type='submit' value="Wyślij" onClick={props.sendEmail}></input>
    </div>
  )
}

export default ContactForm