import React, { useEffect, useState } from 'react'
import Menu from '../../components/menu/menu'
import Header from './components/header/header'
import Body from './components/body/body'
import Footer from './components/footer/footer'
import './mainSite.css'
import MenuCompact from '../../components/menuCompact/menuCompact'


const MainSite = () => {
  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])


  return (
    <>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}
      <Header/>
      <Body/>
      <Footer/>
      <div className='main-background'></div>
    </>
  )
}

export default MainSite