import React from 'react'
import './footer.css'
import './footerMediaQuery.css'
import { Icon } from '@iconify/react';
import { Link, useNavigate } from 'react-router-dom'
import logoLarge from '../../../../media/images/CodeXperience-long-white.webp'



const Footer = () => {
  return (
    <footer>
      <div className='footer-left'>
        <div className='logo-main-footer'>
          <img src={logoLarge} />
        </div>
        <div className='footer-icons'>
          <Icon className='footer-icon' icon="ic:baseline-facebook" />
          <Icon className='footer-icon' icon="ri:instagram-fill" />
        </div>
      </div>
      <div className='footer-right'>
        <h5>KONTAKT</h5>
        <p>Email: jakubwadycki@gmail.com</p>
        <Link to='/contact'><button>Napisz do mnie</button></Link>
      </div>
      <p>Copyright © 2023 All Rights Reserved</p>
    </footer>
  )
}

export default Footer