import React, { useEffect, useState } from 'react'
import { db, auth } from '../../../../config/firebase'
import { getDocs, collection, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import './MyOffers.css'
import './MyOffersMediaQuery.css'
import { Icon } from '@iconify/react';
import { Bounce, ToastContainer, toast } from 'react-toastify';


const MyOffers = () => {

    const offerCollection = collection(db, 'offers')

    const [offerList, setOfferList] = useState([])
    let actualOffer

    const successFunction = (content) => {
        toast.success(content, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        })
    }

    const errorFunction = (content) => {
        toast.error(content, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        })
    }

    const getOfferList = async () => {
        try {
            const data = await getDocs(offerCollection)
            let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

            setOfferList(finalData.filter((offer) => {
                return (
                    offer.author && offer.author.includes(auth?.currentUser?.displayName)
                )
            }))
        }
        catch (err) {
            errorFunction("Błąd ładowania zasobów")
        }
    }

    useEffect(() => {
        getOfferList()
    }, [])

    const showDialogWindow = (e) => {
        document.getElementById('offer-dialog').showModal();
        actualOffer = offerList.filter((offer) => {
            return (
                offer.id && offer.id.includes(e.currentTarget.id.slice(9))
            )
        }
        )

        document.getElementById('offer-title').value = actualOffer[0].title

        actualOffer[0].positions.forEach(position => {
            document.getElementById('offer-jobs').value += `${position},`
        });

        actualOffer[0].technologies.forEach(technology => {
            document.getElementById('offer-technologies').value += `${technology},`
        });

        document.getElementById('offer-describe').value = actualOffer[0].text

    }

    const deleteValuesFromInputs = async () => {
        document.getElementById('offer-title').value = ""
        document.getElementById('offer-jobs').value = ""
        document.getElementById('offer-technologies').value = ""
        document.getElementById('offer-describe').value = ""
    }

    const closeDialogWindow = async () => {
        document.getElementById('offer-dialog').close();
        deleteValuesFromInputs()
    }

    const editOffer = async () => {
        let positions = document.getElementById('offer-jobs').value
        let technologies = document.getElementById('offer-technologies').value
        let text = document.getElementById('offer-describe').value
        let title = document.getElementById('offer-title').value

        if (actualOffer[0].author === auth.currentUser.displayName) {
            if(positions.length > 300){
                errorFunction("Pole 'stanowiska' powinno zaiwerać maksymalnie 300 znaków")
            }
            else if(technologies.length > 300){
                errorFunction("Pole 'technologie' powinno zaiwerać maksymalnie 300 znaków")
            }
            else if(text.length > 2000){
                errorFunction("Pole 'opis' powinno zaiwerać maksymalnie 2000 znaków")
            }
            else if(title.length > 300){
                errorFunction("Pole 'tytuł' powinno zaiwerać maksymalnie 300 znaków")
            }
            else{
                try {
                    await updateDoc(doc(db, 'offers', `${actualOffer[0].id}`), {
                        positions: positions.split(","),
                        technologies: technologies.split(","),
                        text: text,
                        title: title,
                    })
    
                    await getOfferList()
                    await closeDialogWindow()
                    await deleteValuesFromInputs()
    
                    successFunction("Edytowano ogłoszenie")
    
                }
                catch (err) {
                    errorFunction("Błąd edycji")
                }
            }           
        }
    }

    const deleteOffer = async () => {
        if (actualOffer[0].author === auth.currentUser.displayName){
            try{
                await deleteDoc(doc(db, 'offers', `${actualOffer[0].id}`))
                await getOfferList()
                await closeDialogWindow()
                await deleteValuesFromInputs()
    
                successFunction("Usunuęto ogłoszenie")
            }
            catch(err){
                errorFunction("Błąd usuwania")
            }
            
        }
    }

    return (
        <div className='my-offers-container'>
            <h3>Moje ogłoszenia</h3>
            <p>Zarządzaj swoimi ogłoszeniami. W tym miejscu możesz je przeglądać, eydtować i usuwać.</p>
            <div className='my-offers'>
                {offerList.length != 0 ? 
                    offerList.map((offer) => (
                        <div key={offer.id} id={"my-offer-" + offer.id} onClick={(e) => showDialogWindow(e)}>
                            <h4>{offer.title}</h4>
                            <p>{offer.text}</p>
                        </div>
                    ))
                    :
                    <p>Brak ogłoszeń</p>
                }
            </div>

            <dialog className="offer-dialog" id='offer-dialog'>
                <div className='offer-dialog-top-bar'>
                    <p><span>C</span>od<span>eX</span>perience</p>
                    <Icon icon="ph:x-bold" onClick={() => closeDialogWindow()} />
                </div>
                <h4>Edytuj ogłoszenie</h4>
                <div className='edit-offer-inputs'>
                    <input placeholder='Tytuł' id='offer-title' />
                    <input placeholder='Stanowiska (oddzielaj przecinkami, np. grafik, programista)' id='offer-jobs' />
                    <input placeholder='Technologie (oddzielaj przecinkami np. HTML, JS)' id='offer-technologies' />
                    <textarea placeholder='Opis' id='offer-describe'></textarea>
                </div>
                <div className='offer-buttons'>
                    <button onClick={deleteOffer}>Usuń ogłoszenie</button>
                    <button onClick={() => editOffer()}>Edytuj ogłoszenie</button>
                </div>
            </dialog>
            <ToastContainer/>
        </div>
    )
}

export default MyOffers