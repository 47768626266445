import React, { useEffect, useRef, useState } from 'react'
import Menu from '../../components/menu/menu'
import './offerSite.css'
import './offerSiteMediaQuery.css'
import { db } from '../../config/firebase'
import { getDocs, collection } from 'firebase/firestore'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom'
import SingleOffer from './components/singleOffer/singleOffer'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import MenuCompact from '../../components/menuCompact/menuCompact'


const OfferSite = () => {
  const [offerList, setOfferList] = useState([])
  const [searchFilter, setSearchFilter] = useState("")
  const offerCollection = collection(db, 'offers')

  const sortByDate = (a, b) => {
    if (new Date(a.date) > new Date(b.date)) {
        return -1;
    }
    if (new Date(a.date) < new Date(b.date)) {
        return 1;
    }
    return 0;
}

  const getOfferList = async (offerFlt, toFilter = false) => {
    try {
      const data = await getDocs(offerCollection)
      let finalData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

      let filtered = finalData.filter((offer) => {
        return (
          offer.title && offer.title.toLowerCase().includes(offerFlt) || offer.text && offer.text.toLowerCase().includes(offerFlt)
        )
      })

      setOfferList(filtered.sort(sortByDate))

      if(toFilter){
        setOffersToLoad([])
        setLoader(true)
      }
    }
    catch (err) {
      console.err(err)
    }
  }

  const getOffer = (flt) => {
    setSearchFilter(flt)
    getOfferList(flt, true)
  }

  const successFunction = (content) => {
    toast.success(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  useEffect(() => {
    getOfferList(searchFilter)

    const urlParams = new URLSearchParams(window.location.search)
    let code = urlParams.get('code')

    if (code == 1) {
      successFunction("Wysłano zgłoszenie")
    }
    else if(code == 2){
      successFunction("Opublikowano ogłoszenie")
    }
  }, [])

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  /* intersection observer made for lazy loading on website*/

  const [offersToLoad, setOffersToLoad] = useState([])
  const offersRef = useRef()
  const [firstLoad, setFirstLoad] = useState(true)

  const loadOffersToArray = () => {
    let lengthOfOffers = offersRef.current.querySelectorAll('.singleOffer').length;

    offerList.slice(lengthOfOffers, lengthOfOffers+5).forEach((offer, index) => {
      if(offerList.length > lengthOfOffers + index){
        setOffersToLoad(prev => [...prev, offer])

        if(index === 4){
          setFirstLoad(false)
        }
      }
    })
  };

  useEffect(() => {
    offerObserver.observe(offersRef.current.children[offersRef.current.children.length - 1])
  }, [firstLoad, offersToLoad])

  const offerObserver = new IntersectionObserver(entries => {
    const lastCard = entries[0]

    if(lastCard.isIntersecting){
      loadOffersToArray()
      offerObserver.unobserve(lastCard.target)
    }
  })

  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if(loader == true && offerList.length != 0){
      loadOffersToArray()
      setLoader(false)
    }
  }, [offerList])


  return (
    <section className='offer-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}

      <div className='top-search-bar'>
        <div>
          <input placeholder='Wyszukaj...' onChange={(event) => getOffer(event.target.value.toLocaleLowerCase())}></input>
          <Icon icon="ic:baseline-search" />
        </div>
        <Link to="/addOffer"><button>Dodaj ogłoszenie</button></Link>
      </div>

      <div className='offerBox' ref={offersRef}>
        {offersToLoad.length !== 0 ? offersToLoad.map(offer => <SingleOffer id={offer.id} title={offer.title} text={offer.text} positions={offer.positions} technologies={offer.technologies } />) : <p className='empty-offers'>Na razie nic tu nie ma, ale nie przejmuj się. Możesz być pierwszą osobą, która coś ogłosi. Do dzieła!</p>}
      </div>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>

      <ToastContainer />
    </section>
  )
}

export default OfferSite