
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCtHtu6P5X4lsmKOCeFk2FHOeq3xT0yb80",
  authDomain: "codexperience-d0e6a.firebaseapp.com",
  projectId: "codexperience-d0e6a",
  storageBucket: "codexperience-d0e6a.appspot.com",
  messagingSenderId: "201053164214",
  appId: "1:201053164214:web:beab9c92f02a760bf74c17",
  measurementId: "G-HDVYN6VL2Z"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app);
export const db_realtime = getDatabase(app);
export const googleProvider = new GoogleAuthProvider()
