import React from 'react'
import './ChooseComponent.css'
import './ChooseComponentMediaQuery.css'
import { Icon } from '@iconify/react';

const ChooseComponent = (props) => {
    return (
        <div className='choose-component-body'>
            <p>Wybierz blok: </p>
            <button onClick={() => props.setNewBlock('heading')}><Icon icon="fa6-solid:heading" /></button>
            <button onClick={() => props.setNewBlock('image')}><Icon icon="ic:baseline-image" /></button>
            <button onClick={() => props.setNewBlock('list')}><Icon icon="ic:round-list" /></button>
            <button onClick={() => props.setNewBlock('paragraph')}><Icon icon="fa:paragraph" /></button>
            <button onClick={() => props.setNewBlock('spacing')}><Icon icon="tabler:separator-horizontal" /></button>
            <button onClick={() => props.setNewBlock('separator')}><Icon icon="tabler:line-dotted" /></button>
            <button onClick={() => props.setNewBlock('link')}><Icon icon="ph:link-bold" /></button>
            <button className='close-choose-component' onClick={() => props.closeChooseComponent()}><Icon icon="ph:x-bold" /></button>
        </div>
    )
}

export default ChooseComponent