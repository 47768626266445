import React, { useEffect, useState } from 'react'
import './UserChats.css'
import './UserChatsMediaQuery.css'
import { db_realtime } from "../../../../config/firebase"
import { off, onValue, ref, set } from "firebase/database"
import { auth } from '../../../../config/firebase'
import { useLocation } from 'react-router-dom'
import { Bounce, ToastContainer, toast } from 'react-toastify'

//TODO: dodać promisy dla ładnowania danych usera, a potem ulepszyć powiadomienian[p]

const UserChats = (props) => {
    const location = useLocation()
    const [message, setMessage] = useState("")
    const [listVisibility, setListVisibility] = useState("none")
    const [searchUsername, setSearchUsername] = useState("")
    const [actualSecondUser, setActualSecondUser] = useState("")
    const [actualIndex, setActualIndex] = useState(0)
    const [listOfUsers, setListOfUsers] = useState([])
    const [usersMessages, setUsersMessages] = useState([])
    const [actualSecondUserName, setActualSecondUserName] = useState("")
    const [lastMessage, setLastMessage] = useState({})
    const [usersConvNames, setUsersConvNames] = useState([])
    const [actualUser, setActualUser] = useState('');
    const [actualSecondUserImage, setActualSecondUserImage] = useState("")
    
    const errorFunction = (content) => {
        toast.error(content, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        })
    }

    useEffect(() => { // function loading username to state
        const checkAuthState = async () => {
            await new Promise((resolve) => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    if (user) {
                        setActualUser(user)
                        resolve();
                    }
                    unsubscribe();
                });
            });
        };

        checkAuthState();
    }, []);

    //change visibility of users search list

    const listOfUsersHandle = (user) => {
        if (user.length > 0) {
            setListVisibility("block")
        }
        else {
            setListVisibility("none")
        }
    }

    //function to search user

    const readData = (searched) => {
        const text = ref(db_realtime, '/USERS/')
        onValue(text, (snapshot) => {
            const objData = snapshot.val()

            const data = Object.values(objData)

            const searchedUsers = data.filter((user) => {
                return (
                    user.name && user.name !== actualUser.displayName && user.name.toLowerCase().includes(searched)
                )
            })

            setListOfUsers(searchedUsers)
        })
    }

    const searchUserHandle = (user) => {
        setSearchUsername(user)

        readData(user)
        listOfUsersHandle(user)
    }


    //function to load messages of user after clicking on name

    const loadUsersMessages = async (secondUser, secondUserName, profileImage = null, area = null) => {

        setActualSecondUser(secondUser)
        setActualSecondUserName(secondUserName)

        if(area == null){
            setActualSecondUserImage(document.querySelector(`#image-${secondUser}`).style.backgroundImage.slice(5,-2))
        }
        else{
            setActualSecondUserImage(profileImage)
        }

        const msg = ref(db_realtime, `usersChats/${actualUser.uid}/${actualUser.uid}-msg-${secondUser}`)

        onValue(msg, async (snapshot) => {
            if (snapshot.val() === null) {
                await set(ref(db_realtime, `usersChats/${actualUser.uid}/${actualUser.uid}-msg-${secondUser}`), "")
                await set(ref(db_realtime, `usersChats/${secondUser}/${secondUser}-msg-${actualUser.uid}`), "")
            }
            else {
                let newMessages = Object.values(snapshot.val()).sort((a, b) => { return a.index - b.index })             //ustawiamy nowe wiadomosci. Konwersja ogólnego obj na tablice wartosci oraz sortowanie
                setUsersMessages(newMessages)                                                                            //adding to array sorted by index messages

                Object.keys(snapshot.val()).length > 0 && setActualIndex(newMessages[newMessages.length - 1].index)
            }
        })

        document.getElementById("search-user-input").value = ""                                                          //remove value of input for searching users
        setListVisibility("none")                                                                                        //and setting them display: none  
    }

    useEffect(() => {
        const element = document.getElementById('messages');
        element.scrollTop = element.scrollHeight;
    }, [usersMessages])


    const setNewMessage = () => {
        if(message.length > 500){
            errorFunction("Twoja wiadomość jest zbyt długa, maksymalny rozmiar to 500 znaków")
        }
        else if(actualSecondUser == ""){
            errorFunction("Nieznany użytkownik")
        }
        else{
            let date = new Date()

            document.getElementById("send-message-input").value = ""
            // document.getElementById("messages").scrollTo(0, document.getElementById("messages").scrollHeight)
    
            if(message != ""){
                try {
                    set(ref(db_realtime, `/usersChats/${actualUser.uid}/${actualUser.uid}-msg-${actualSecondUser}/msg_${actualIndex + 1}`), {
                        author: actualUser.displayName,
                        date: date.getDate() + '.' + (date.getMonth() + 1) + '.' + (Number(date.getYear()) + 1900),
                        index: actualIndex + 1,
                        text: message,
                        time: date.getUTCHours() + 2 + ':' + (date.getUTCMinutes() > 10 ? date.getUTCMinutes() : `0${date.getUTCMinutes()}`),
                    })
        
                    set(ref(db_realtime, `/usersChats/${actualSecondUser}/${actualSecondUser}-msg-${actualUser.uid}/msg_${actualIndex + 1}`), {
                        author: actualUser.displayName,
                        date: date.getDate() + '.' + (date.getMonth() + 1) + '.' + (Number(date.getYear()) + 1900),
                        index: actualIndex + 1,
                        text: message,
                        time: date.getUTCHours() + 2 + ':' + (date.getUTCMinutes() > 10 ? date.getUTCMinutes() : `0${date.getUTCMinutes()}`),
                    })
        
                    setActualIndex(actualIndex + 1)
                    setMessage("");
                }
                catch (err) {
                    console.log(err)
                }
            }
        }
    }

    //setMessageByEnter
    const setMessageByEnter = (e) => {
        if(e.key == "Enter"){
            if(!e.shiftKey){
                setNewMessage()
            }
        }
    }

    //get last messages of users

    const getLastMessage = () => {
        const msg = ref(db_realtime, `usersChats/${actualUser.uid}`);

        onValue(msg, (snapshot) => {
            let messages = Object.values(snapshot.val());
            let keys = Object.keys(snapshot.val());

            //dodajemy do obecnego obiektu z ostatnią wiadomością, kolejny obiekt, którego kluczem jest uid usera a wartością ostatnia wiasomość (element - kolejne wartości iteracji, messages - tablica z wiadomościami)

            for (let element in messages) {

                let valOfUserMsg = Object.values(messages[element]).sort((a, b) => { return a.index - b.index }) //posorotwana tablica wiadomości użytkownika danej iteracji

                if (valOfUserMsg[valOfUserMsg.length - 1] !== undefined) {
                    let author = valOfUserMsg[valOfUserMsg.length - 1].author === actualUser.displayName ? 'Ty' : valOfUserMsg[valOfUserMsg.length - 1].author //autor wiadomości
                    let textOfMessage = valOfUserMsg[valOfUserMsg.length - 1].text //wiadomość

                    setLastMessage(prevLastMessage => ({ ...prevLastMessage, [keys[element].slice(33)]: `${author}: ${textOfMessage}` })) //przypisanie do tablicy obiektu gdzie kluczem jest UID użytkownika a wartością string z autorem i wiadomością
                }
            }
        })
    }

    //list of users conversations


    const getUserConversations = () => {
        if (actualUser.uid != undefined) {
            const msg = ref(db_realtime, `usersChats/${actualUser.uid}`)

            onValue(msg, (snapshot) => {
                let usersConv = Object.keys(snapshot.val())
                const msgInside = ref(db_realtime, `USERS`)

                onValue(msgInside, (snapshot) => {
                    const data = Object.values(snapshot.val())
                    setUsersConvNames([])

                    for (let dataRow of usersConv) {
                        const boxForUser = data.filter((fData) => {
                            return (
                                fData.UID && fData.UID.includes(dataRow.slice(33))
                            )
                        })
                        setUsersConvNames(usersConvNames => [...usersConvNames, boxForUser])
                    }
                })
            })
            getLastMessage()
        } 
    }


    useEffect( () => {
        const urlParams = new URLSearchParams(window.location.search)
        let userID = urlParams.get('user')

        getUserConversations()

        if (userID !== null && actualUser.uid != undefined) { //loading conversation with user if is set username from volunteers
            let filteredUser

            const user = ref(db_realtime, 'USERS')
            onValue(user, (snapshot) => {
                const data = Object.values(snapshot.val())
                filteredUser = data.filter((user) => {
                    return (
                        user.UID && user.UID.includes(userID)
                    )
                })
                
                loadUsersMessages(userID, filteredUser[0].name, filteredUser[0].profileImage, true)
                
            })
        }

    }, [actualUser])

    return (
        <div className='chat-container'>
            <div className='users-section'>
                <div className='search-bar-and-icon'>
                    <input id='search-user-input' placeholder='Szukaj użytkownika' onChange={(event) => searchUserHandle(event.target.value.toLowerCase())}></input>                                       
                </div>
                <div className='list-of-users' style={{ display: listVisibility }}>
                    {listOfUsers.length > 0 ? listOfUsers.map((user) => (
                        <p key={user.UID} id={user.UID} data-profile-image={user.profileImage} onClick={(e) => loadUsersMessages(e.target.id, e.target.innerText, e.target.getAttribute('data-profile-image'), true)}>{user.name}</p>
                    )) : <p>Brak</p>}
                </div>
                <div className='list-of-last-conversations'>

                    <h2>Czaty</h2>

                    {usersConvNames.length > 0 ? usersConvNames.map((conv) => (
                        <div className='user-conv' key={conv[0].UID} data-userid={conv[0].UID} data-username={conv[0].name} onClick={(e) => loadUsersMessages(e.currentTarget.getAttribute('data-userid'), e.currentTarget.getAttribute('data-username'))}>
                            <div id={`image-${conv[0]?.UID}`} style={{ backgroundImage: `url(${conv[0]?.profileImage})` }}></div>
                            <div className='user-and-last-msg' id={`ualm-${conv[0]?.UID}`}>
                                <p id={conv[0]?.UID}>{conv[0]?.name}</p>
                                <p>{lastMessage[conv[0]?.UID]}</p>
                            </div>
                        </div>
                    )) : <p className='empty-chats'>Brak konwersacji</p>}
                </div>
            </div>
            <div className='separator'></div>
            <div className='chat-section'>
                <div className='secondUserData'>
                    <div style={{backgroundImage: 'url('+actualSecondUserImage+')'}}></div>
                    <p>{actualSecondUserName}</p>
                </div>
                <div className='messages' id="messages">
                    

                    {usersMessages.length > 0 ? usersMessages.map((msg) => (
                        <div key={msg?.index} className={msg?.author === actualUser.displayName ? 'msg-author' : 'msg-guest'}>
                            <p>{`${msg?.author} ${msg?.date} ${msg?.time}`}</p>
                            <p>{msg?.text}</p>
                        </div>
                    )) : <p className='empty-messages'>Brak wiadomości</p>}
                </div>
                <div className='send-message-container'>
                    <input id="send-message-input" type='text' placeholder='Wyślij wiadomość' onKeyDown={(e) => setMessageByEnter(e)} onChange={(e) => setMessage(e.target.value)}></input>
                    <button onClick={setNewMessage}>Wyślij</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}

export default UserChats