import React, { useEffect, useState } from 'react'
import './ListsComponent.css'
import './ListsComponentMediaQuery.css'
import { Icon } from '@iconify/react';
import { Bounce, toast } from 'react-toastify';

const ListsComponent = (props) => {
    const [listStyle, setListStyle] = useState("ul")
    const [align, setAlign] = useState("center")
    const [color, setColor] = useState("#000")
    
    const [bold, setBold] = useState(false)
    const [italic, setItalic] = useState(false)
    const [underline, setUnderline] = useState(false)
    const [listElementsState, setListElementsState] = useState([])
    
    const errorFunction = (content) => {
        toast.error(content, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        })
    }
    
    const addElement = (e) => {
        if(e.key == "Enter"){
            e.preventDefault()

            const list = document.querySelector('.users-list')

            const li = document.createElement('li')
            li.setAttribute('contenteditable', 'true')
            li.addEventListener('keydown', (e) => editList(e))
            li.addEventListener('keypress', (e) => addElement(e))

            list.appendChild(li)

            const lastElement = document.querySelector('.users-list li:last-child')
            lastElement.focus()
        }

        if(e.key == 'b' || e.key == 'B' || e.key == 'u' || e.key == 'U' || e.key == 'i' || e.key == 'I'){
            if(e.ctrlKey){
                e.preventDefault()
            }
        }
    }
    
    const editList = (e) => {
        if(e.key == "Backspace"){  
            if(e.target.innerHTML == "" && document.querySelectorAll('.users-list li').length > 1){
                document.querySelector('.users-list').removeChild(e.target)
                let lastLi = document.querySelector('.users-list li:last-child')

                const range = document.createRange();
                const sel = window.getSelection();
                range.selectNodeContents(lastLi);
                range.collapse(false);
                sel.removeAllRanges();
                sel.addRange(range);
                lastLi.focus()
                
            }
        }

        
    }


    const setListsComponent = () =>{
        let contentList = document.querySelectorAll('.users-list li')
        let content = []
        contentList.forEach(li => content.push(li.innerText))
        
        if(content.length === 0){
            props.clearComponentArray()
        }
        else if(content.length > 3000){
            errorFunction("Treść nie może przekraczać 3000 znaków")
        }
        else{
            props.setNewList(listStyle, bold, italic, underline, align, color, content)
        }
    }

    const setNewData = () => {
        let contentList = document.querySelectorAll('.users-list li')
        let content = []
        contentList.forEach(li => content.push(li.innerText))

        if(content.length === 0){
            props.clearComponentArray()
        }
        else if(content.length > 3000){
            errorFunction("Treść nie może przekraczać 3000 znaków")
        }
        else{
            props.editBlock(['list', props.data[0], listStyle, bold, italic, underline, align, color, content])
        } 
    }

    useEffect(() => {
        if(props.edit == true){
            setListStyle(props.data[2])
            setAlign(props.data[6])
            setColor(props.data[7])
            setBold(props.data[3])
            setItalic(props.data[4])
            setUnderline(props.data[5])

            setListElementsState(props.data[8])
        }
    }, [])

    const closeDialog = () => {
        setListStyle(props.data[2])
        setAlign(props.data[6])
        setColor(props.data[7])
        setBold(props.data[3])
        setItalic(props.data[4])
        setUnderline(props.data[5])

        setListElementsState(props.data[8])
        document.getElementById('edit-element-dialog').close()
    }

    return (
        <div className='lists-component-body'>
            <div className='options'>
                <button onClick={() => setListStyle('ul')}><Icon icon="material-symbols:lists-rounded" /></button>
                <button onClick={() => setListStyle('ol')}><Icon icon="fluent:text-number-list-16-filled" /></button>

                <button onClick={() => setBold(bold => !bold)}><Icon icon="octicon:bold-16" /></button>
                <button onClick={() => setItalic(italic => !italic)}><Icon icon="mingcute:italic-fill" /></button>
                <button onClick={() => setUnderline(underline => !underline)}><Icon icon="mingcute:underline-fill" /></button>
                               
                <button onClick={() => setAlign("start")}><Icon icon="mingcute:align-left-fill" /></button>
                <button onClick={() => setAlign("center")}><Icon icon="mingcute:align-center-fill" /></button>
                <button onClick={() => setAlign("end")}><Icon icon="mingcute:align-right-fill" /></button>

                <input type='color' onChange={(e) => setColor(e.target.value)}></input>

                <button className='accept' onClick={props.edit == true ? setNewData : setListsComponent}><Icon icon="mingcute:check-fill" /></button>
                <button className='delicine' onClick={props.edit == true ? closeDialog : props.clearComponentArray}><Icon icon="octicon:x-16" /></button>
            </div>
            <div className='lists-input' style={{justifyContent: align}}>
                {listStyle == 'ul' ? 
                <ul className='users-list' style={{color: color, fontWeight: `${bold == true ? 'bold' : 'normal'}`, fontStyle: `${italic == true ? 'italic' : 'normal'}`, textDecoration: `${underline == true ? 'underline' : 'none'}`}}>
                    {listElementsState.length > 0 ? listElementsState.map((li) => <li contentEditable="true" suppressContentEditableWarning="true" onKeyDown={(e) => editList(e.target)} onKeyDownCapture={(e) => addElement(e)}>{li}</li>)  
                    :
                    <li contentEditable="true" suppressContentEditableWarning="true" onKeyDown={(e) => editList(e.target)} onKeyDownCapture={(e) => addElement(e)}></li>
                    }
                </ul> 
                : 
                <ol className='users-list' style={{color: color, fontWeight: `${bold == true ? 'bold' : 'normal'}`, fontStyle: `${italic == true ? 'italic' : 'normal'}`, textDecoration: `${underline == true ? 'underline' : 'none'}`}}>
                    {listElementsState.length > 0 ? listElementsState.map((li) => <li contentEditable="true" suppressContentEditableWarning="true" onKeyDown={(e) => editList(e.target)} onKeyDownCapture={(e) => addElement(e)}>{li}</li>)  
                    :
                    <li contentEditable="true" suppressContentEditableWarning="true" onKeyDown={(e) => editList(e.target)} onKeyDownCapture={(e) => addElement(e)}></li>
                    }
                </ol>}
            </div>
        </div>
    )
}

export default ListsComponent