import React, { useEffect, useState } from 'react'
import './userPanel.css'
import './userPanelMediaQuery.css'
import { auth } from '../../config/firebase'
import Menu from '../../components/menu/menu'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import UserPanelDetails from './components/userPanelDetails/userPanelDetails'
import MenuCompact from '../../components/menuCompact/menuCompact'

const UserPanel = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [rightContent, setRightContent] = useState("UserPanelInfo")
  const [actualUser, setActualUser] = useState({})

  useEffect(() => { // function loading username to state
    const checkAuthState = async () => {
        await new Promise((resolve) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                if (user) {
                    setActualUser(user)
                    resolve();
                }
                unsubscribe();
            });
        });
    };

    checkAuthState();
}, []);

  const logoutHandle = () => {
    signOut(auth)
    navigate("/login?code=6")
  }

  useEffect(() => {
    if(auth.currentUser == null){
      navigate('/login')
    }

    if(location.state?.code == "UserPanelChat"){
      setRightContent("UserPanelChat");
      location.state.code = ""
    }
  }, [])

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  return (
    <section className='user-settings-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}
      <div className='user-panel'>
        <div className='user-panel-top-bar'>
          <h1>Konto CodeXperience</h1>
          <button onClick={logoutHandle}>Wyloguj się</button>
        </div>
        <hr />

        <div className='user-panel-bottom'>
          <div className='left-user-panel'>
            <div>
              <div className='user-photo' style={{ backgroundImage: `url(${actualUser.photoURL})` }}></div>
              <h2>{actualUser.displayName}</h2>
              <p>{actualUser.email}</p>
            </div>
            

            <div className='user-panel-menu'>
              <button onClick={() => setRightContent("UserPanelInfo")}>Informacje o koncie</button>
              <button onClick={() => setRightContent("UserPanelOffers")}>Moje Ogłoszenia</button>
              <button onClick={() => setRightContent("UserPanelProjects")}>Moje Projekty</button>
              <button onClick={() => setRightContent("UserPanelVolunteers")}>Zgłoszenia</button>
              <button onClick={() => setRightContent("UserPanelChat")}>Rozmowy</button>
            </div>

          </div>
          <div className='right-user-panel' key={rightContent}>
            <UserPanelDetails option={rightContent}/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UserPanel