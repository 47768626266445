import React from 'react'
import './bodyBlock.css'
import './bodyBlockMediaQuery.css'
import { Icon } from '@iconify/react';

const BodyBlock = (props) => {
    return (
        <div className='body-block'>
            <Icon icon={props.icon} />
            <h4>{props.title}</h4>
            <p>{props.text}</p>
        </div>
    )
}

export default BodyBlock