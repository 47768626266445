import React, { useEffect, useState } from 'react'
import Menu from '../../components/menu/menu'
import './loginSite.css'
import './loginSiteMediaQuery.css'
import computerGuyImage from '../../media/images/computer-guy.webp'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { auth, db_realtime, googleProvider } from '../../config/firebase'
import { Icon } from '@iconify/react';
import { onValue, ref, set } from 'firebase/database'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import MenuCompact from '../../components/menuCompact/menuCompact'

const LoginSite = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [logError, setLogError] = useState("")

  const signUp = async (event) => {
    try {
      event.preventDefault()
      await signInWithEmailAndPassword(auth, email, password)
      navigate("/offer")
    }
    catch (err) {
      if (err.code === "auth/invalid-email") {
        setLogError("Nieprawidłowy email lub hasło")
      }
      else {
        setLogError("Błąd logowania, spróbuj ponownie później")
      }
    }
  }

  const signUpGoogle = async (event) => {
    try {
      event.preventDefault()
      await signInWithPopup(auth, googleProvider)

      try {
        const msg = ref(db_realtime, `usersChats/${auth.currentUser.uid}`)

        onValue(msg, (snapshot) => {
          if (snapshot.val() == null) {
            set(ref(db_realtime, `/USERS/${auth.currentUser.uid}`), {
              UID: `${auth.currentUser.uid}`,
              name: `${auth.currentUser.displayName}`,
              profileImage: `${auth.currentUser.photoURL}`,
            })

            set(ref(db_realtime, `/usersChats/${auth.currentUser.uid}`), "")
          }
        })
      }
      catch (error) {
        setLogError("Błąd w bazie danych... Spróbuj ponownie później")
      }

      navigate("/offer")
    }
    catch (err) {
      setLogError("Błąd logowania, spróbuj ponownie później")
    }
  }

  const succesFunction = (content) => {
    toast.success(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const errorFunction = (content) => {
    toast.error(content, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  useEffect(() => {
    if(auth.currentUser != null){
      navigate('/')
    }


    const urlParams = new URLSearchParams(window.location.search)
    let code = urlParams.get('code')

    if(code == 1){
      succesFunction("Stworzono konto")
    }
    else if(code == 2){
      succesFunction("Zmieniono email - zaloguj się ponownie")
    }
    else if(code == 3){
      succesFunction("Zmieniono nazwę - zaloguj się ponownie")
    }
    else if(code == 4){
      succesFunction("Zmieniono hasło - zaloguj się ponownie")
    }
    else if(code == 5){
      succesFunction("Usunięto konto")
    }
    else if(code == 6){
      succesFunction("Wylogowano")
    }
    else if(code == 7){
      errorFunction("Zaloguj się aby dodawać ogłoszenia")
    }
    else if(code == 8){
      errorFunction("Zaloguj się aby dodawać projekty")
    }
    else if(code == 9){
      errorFunction("Zaloguj się aby odpowiadać na ogłoszenia")
    }

  }, [])

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  return (
    <section className='login-section'>
      <div className='background'></div>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}

      <div className='login-container'>
        <div className='left-login'>
          <h1>Zaloguj się</h1>
          <form>
            <p className='errors'>{logError}</p>
            <input type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)}></input>
            <input type='password' placeholder='Hasło' onChange={(e) => setPassword(e.target.value)}></input>
            <button type='submit' onClick={signUp}>Zaloguj</button>
          </form>
          <p>lub</p>
          <button className='googleLogin' onClick={signUpGoogle}><Icon icon="bi:google" /> Zaloguj się za pomoca konta google </button>
          <p>Nie masz jeszcze kont? <Link title='Przycisk rejestracja' to='/register'>Zarejestruj się tutaj</Link></p>

        </div>
        <div className='right-login'>
          <img src={computerGuyImage} />
        </div>
      </div>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>

      <ToastContainer/>
    </section>
  )
}

export default LoginSite