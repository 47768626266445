import React, { useEffect, useState } from 'react'
import Menu from '../../components/menu/menu'
import './addOfferSite.css'
import './addOfferSiteMediaQuery.css'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { addDoc, collection } from 'firebase/firestore'
import { db, auth } from '../../config/firebase'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import MenuCompact from '../../components/menuCompact/menuCompact'


const AddOfferSite = () => {
  const offerCollection = collection(db, 'offers')
  const navigate = useNavigate()

  const [title, setTitle] = useState("")
  const [positions, setPositions] = useState("")
  const [technologies, setTechnologies] = useState("")
  const [text, setText] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    if(!auth?.currentUser?.email){
      navigate("/login?code=7")
    }
  }, [])


  const errorFunction = (content) => {
    toast.error(content, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    })
  }

  const createOffer = async () => {
    let date = new Date();

    if (title.length === 0) {
      setError("Tytuł nie może być pusty!")
    }
    else if (title.length > 300) {
      setError("Tytuł nie może przekraczać 500 znaków!")
    }
    else if (positions.length === 0) {
      setError("Ogłoszenie musi zawierać stanowiska!")
    }
    else if (positions.length > 300) {
      setError("Stanowiska nie mogą przekraczać 300 znaków!")
    }
    else if (technologies.length === 0) {
      setError("Ogłoszenie musi zawierać informacje o technologiach!")
    }
    else if (technologies.length > 300) {
      setError("Technologie nie mogą przekraczać 300 znaków!")
    }
    else if (text.length === 0) {
      setError("Opis nie może być pusty!")
    }
    else if (text.length > 1000) {
      setError("Opis nie może przekraczać 2000 znaków!")
    }
    else {
      try {
        await addDoc(offerCollection, {
          author: auth.currentUser.displayName,
          authorUID: auth.currentUser.uid,
          date: `${(Number(date.getYear()) + 1900) + '-' + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + Number(date.getMonth() + 1)) + '-' + date.getDate()}`,
          positions: positions.split(","),
          technologies: technologies.split(","),
          text: text,
          title: title,
        })

        navigate("/offer?code=2")
      }
      catch (err) {
        errorFunction("Bład dodawania ogłoszenia")
      }
    }
  }

  const [menuVersion, setMenuVersion] = useState(0)

  //changing menu to compact version if window is less than 992 px

  let media = window.matchMedia("(max-width: 992px)")

  const resizeListener = () => {
    if(media.matches){
      if(menuVersion === 0){
        setMenuVersion(1)
      }
    }
    else{
      if(menuVersion === 1){
        setMenuVersion(0)
      }
    }
  }

  window.addEventListener('resize', resizeListener)
  useEffect(() => {resizeListener()}, [])

  return (
    <section className='add-offer-section'>
      {menuVersion == 0 ? <Menu/> : <MenuCompact/>}

      <Link className='back-to-offers' to="/offer"> <Icon icon="material-symbols:arrow-left" /> Powrót do ogłoszeń </Link>

      <div className='add-offer'>
        <h1>Dodaj ogłoszenie</h1>
        <p>Dodaj nowe ogłoszenie. Podaj w nim tytuł oraz informacje na temat technologii jakich zamieżasz użyć. Napisz także kogo szukasz, wpisz nazwy stanowisk. Na końcu napisz coś o swoim projekcie. Daj znać innym do jakiego projektu masz zamiar ich zaprosić. Zachęć ich do współpracy. Pamiętak także o przestrzeganiu regulaminu.</p>

        <div className='add-offer-form'>
          <p className='add-offer-error'>{error}</p>
          <input placeholder='Tytuł (max 300 znaków)' onChange={(e) => setTitle(e.target.value)}></input>
          <input placeholder='Stanowiska (max 300 znaków, oddzielaj przecinkiem, np. grafik, programista)' onChange={(e) => setPositions(e.target.value)}></input>
          <input placeholder='Technologie (max 300 znaków, oddzielaj przecinkiem, np. JS, HTML)' onChange={(e) => setTechnologies(e.target.value)}></input>
          <textarea placeholder='Opis (max 2000 znaków)' onChange={(e) => setText(e.target.value)}></textarea>

          <button onClick={createOffer}>Stwórz ogłoszenie</button>
        </div>
      </div>

      <div className='image-background'>
        <div className='dark-cover-background'></div>
      </div>

      <ToastContainer/>
    </section>
  )
}

export default AddOfferSite