import React, { useEffect, useState } from 'react'
import './ImageComponent.css'
import './ImageComponentMediaQuery.css'
import { Icon } from '@iconify/react';
import emptyImage from '../../../../media/images/prev.webp'
import { auth } from '../../../../config/firebase';

const ImageComponent = (props) => {
    const [file, setFile] = useState(null)
    const formats = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp']
    const [width, setWidth] = useState(300)
    const [height, setHeight] = useState(300)
    const [align, setAlign] = useState("start")

    const fileUpdate = (e) => {
        if (!formats.includes(e.currentTarget.files[0].type)) {
            e.target.value = null;
            console.error("Nieobsługowany format pliku, wybierz inny plik")
        }
        else if (e.currentTarget.files[0].size > 1048576) {
            e.target.value = null;
            console.error("Plik jest za duży, maksymalny rozmiar pliku to 1 MB")
        }
        else {
            const selectedFile = e.currentTarget.files[0]
            setFile(selectedFile)
        }
    }

    const sendImage = async () => {
        if (file != null) {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('user', auth.currentUser.uid)

            fetch('https://codexperience.przybyslawice.kylos.pl/static/php/uploadFile.php', {
                method: 'POST',
                body: formData,
            })
            .then((response) => { console.log('Wysłano plik na serwer', response) })
            .catch((err) => { console.error(err) })
        }
    }

    const setImageComponent = async () => {
        await sendImage()

        props.setNewImage(width, height, align, file.name)
    }

    const setNewData = () => {
        props.editBlock(['image', props.data[0], width, height, align])
    }

    useEffect(() => {
        if (props.edit == true) {
            setWidth(Number(props.data[2]))
            setHeight(Number(props.data[3]))
            setAlign(props.data[4])
        }
    }, [])

    const closeDialog = () => {
        setWidth(Number(props.data[2]))
        setHeight(Number(props.data[3]))
        setAlign(props.data[4])
        document.getElementById('edit-element-dialog').close()
    }

    return (
        <div className='image-component-body'>
            <div className='options'>
                <label>Wysokość: <input type="number" defaultValue={props.data ? props.data[3] : 300} onChange={(e) => setHeight(e.target.value > 300 ? 300 : e.target.value < 0 ? 1 : e.target.value)}></input></label>
                <label>Szerokość: <input type="number" defaultValue={props.data ? props.data[2] : 300} onChange={(e) => setWidth(e.target.value > 300 ? 300 : e.target.value < 0 ? 1 : e.target.value)}></input></label>
                <button onClick={() => setAlign("start")}><Icon icon="mingcute:align-left-fill"/></button>
                <button onClick={() => setAlign("center")}><Icon icon="mingcute:align-center-fill"/></button>
                <button onClick={() => setAlign("end")}><Icon icon="mingcute:align-right-fill" /></button>
                <button className='accept' onClick={props.edit == true ? setNewData : setImageComponent}><Icon icon="mingcute:check-fill" /></button>
                <button className='delicine' onClick={props.edit == true ? closeDialog : props.clearComponentArray}><Icon icon="octicon:x-16"/></button>
            </div>
            <div className='image-input'>
                {(file != null || props.edit == true) &&
                    <div className='example-image' style={{ justifyContent: align }}>
                        <img width={width} height={height} src={emptyImage} />
                    </div>}

                {props.edit != true &&
                    <>
                        <label for="insert-image-post">{file == null ? "Kliknij aby wybrać zdjęcie" : file.name}</label>
                        <input id='insert-image-post' onChange={(e) => fileUpdate(e)} type="file"></input>
                    </>
                }
            </div>
        </div>
    )
}

export default ImageComponent