import React, { useEffect, useRef, useState } from 'react'
import { auth, db, db_realtime, googleProvider } from '../../../../config/firebase'
import { Icon } from '@iconify/react';
import './AccountInfo.css'
import './AccountInfoMediaQuery.css'
import { signOut, reauthenticateWithCredential, EmailAuthProvider, updateProfile, updateEmail, updatePassword, deleteUser, reauthenticateWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { getDatabase, off, onValue, ref, set, update } from 'firebase/database';
import { collection, deleteDoc, doc, getDoc, getDocs } from 'firebase/firestore';



const AccountInfo = () => {

    const [isEnabled, setIsEnabled] = useState(0)
    const [isEnabledUs, setIsEnabledUs] = useState(0) //delete user
    const [newEmail, setNewEmail] = useState("")
    const [newName, setNewName] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate()

    const errorFunction = (content) => {
        toast.error(content, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        })
    }

    const updateNameData = async () => {
        update(ref(db_realtime, `/USERS/${auth.currentUser.uid}`), {
            name: newName
        })
    }

    const isEmail = (email) => {
        const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        return pattern.test(email)
    }

    const changeEmail = async (e) => {
        e.preventDefault()

        if (auth.currentUser.providerData[0].providerId !== 'google.com') {
            if (newEmail.length <= 0 || newEmail.length > 100) {
                errorFunction("Nieprawidłowa długość adresu email")
            }
            else if (!isEmail(newEmail)) {
                errorFunction("Podany ciąg znaków nie jest adresem email")
            }
            else {
                try {
                    const credentials = EmailAuthProvider.credential(
                        auth.currentUser.email,
                        password
                    )

                    await reauthenticateWithCredential(auth.currentUser, credentials)
                    await updateEmail(auth.currentUser, newEmail)
                    await signOut(auth)
                    navigate("/login?code=2")
                }
                catch (error) {
                    errorFunction("Błąd aktualizacji danych")
                }
            }
        }
        else {
            errorFunction("Zmiana danych niedostępna dla kont google")
        }
    }

    const changeName = async (e) => {
        e.preventDefault()

        if (auth.currentUser.providerData[0].providerId !== 'google.com') {
            if (newName.length > 0 && newName.length <= 30) {
                try {
                    const credentials = EmailAuthProvider.credential(
                        auth.currentUser.email,
                        password
                    )

                    await reauthenticateWithCredential(auth.currentUser, credentials)
                    await updateNameData()
                    await signOut(auth)
                    navigate("/login?code=3")
                }
                catch (error) {
                    errorFunction("Błąd aktualizacji danych")
                }
            }
            else {
                errorFunction("Długość nazwy powinna zawierać się w przedziale 1-30 znaków")
            }

        }
        else {
            errorFunction("Zmiana danych niedostępna dla kont google")
        }
    }

    const changePass = async (e) => {
        e.preventDefault()

        if (auth.currentUser.providerData[0].providerId !== 'google.com') {
            if (newPassword.length > 0 && newPassword <= 50) {
                try {
                    const credentials = EmailAuthProvider.credential(
                        auth.currentUser.email,
                        password
                    )

                    await reauthenticateWithCredential(auth.currentUser, credentials)
                    await updatePassword(auth.currentUser, newPassword)
                    await signOut(auth)
                    navigate("/login?code=4")
                }
                catch (error) {
                    errorFunction("Błąd aktualizacji danych")
                }
            }
            else {
                errorFunction("Nieodpowiednia długość hasła")
            }

        }
        else {
            errorFunction("Zmiana danych niedostępna dla kont google")
        }
    }

    const deleteUserData = async () => {
        //deleting user offers

        const offerCollection = collection(db, "offers")
        let offers = await getDocs(offerCollection)
        let finalOffers = offers.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

        let offersToRemove = finalOffers.filter((offer) => {
            return (
                offer.authorUID && offer.authorUID.includes(auth.currentUser.uid)
            )
        })

        offersToRemove.forEach(async (offer) => {
            await deleteDoc(doc(db, "offers", `${offer.id}`))
        })

        //deleting user volunteers

        const volunteerCollection = collection(db, "volunteers")
        let volunteers = await getDocs(volunteerCollection)
        let finalVolunteers = volunteers.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

        let volunteersToRemove = finalVolunteers.filter((vol) => {
            return (
                vol.userUID && vol.userUID.includes(auth.currentUser.uid)
            )
        })

        volunteersToRemove.forEach(async (vol) => {
            await deleteDoc(doc(db, "volunteers", `${vol.id}`))
        })

        //deleting users public projects

        const pProjectsCollection = collection(db, "publicProjects")
        let pProjects = await getDocs(pProjectsCollection)
        let finalpProjects = pProjects.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

        let pProjectsToRemove = finalpProjects.filter((pP) => {
            return (
                pP.authorID && pP.authorID.includes(auth.currentUser.uid)
            )
        })

        pProjectsToRemove.forEach(async (pP) => {
            await deleteDoc(doc(db, "publicProjects", `${pP.id}`))
        })

        test()

        set(ref(db_realtime, `/usersChats/${auth.currentUser.uid}`), null)
        set(ref(db_realtime, `/notifications/${auth.currentUser.uid}`), null)
        set(ref(db_realtime, `/USERS/${auth.currentUser.uid}`), null)
    }

    const [deleted, setDeleted] = useState(false)

    const test = () => {
        if (deleted == false) {
            const users = ref(db_realtime, `/usersChats/${auth.currentUser.uid}`)
            onValue(users, (snapshot) => {
                const objData = snapshot.val()
                const data = Object.keys(objData)
                let testowe = []

                data.forEach((id) => { testowe.push(id.slice(33)) })

                testowe.forEach((id) => { set(ref(db_realtime, `/usersChats/${id}/${id}-msg-${auth.currentUser.uid}`), null) })
            })
            setDeleted(true)
        }
    }


    const deleteUserFromDb = async () => {
        try {
            if (auth.currentUser.providerData[0].providerId !== 'google.com') {
                const credentials = EmailAuthProvider.credential(
                    auth.currentUser.email,
                    password
                )

                await reauthenticateWithCredential(auth.currentUser, credentials)

            }
            else {
                await reauthenticateWithPopup(auth.currentUser, googleProvider)
            }


            await deleteUserData()
            await deleteUser(auth.currentUser)
            await signOut(auth)
            navigate("/login?code=5")

        }
        catch (error) {
            errorFunction(error)
        }
    }

    const enableChangeOption = (event) => {
        if (isEnabled === 0) {
            event.currentTarget.lastChild.className = "new-data enabled"
            setIsEnabled(1)
        }

    }

    const disableChangeOption = (event) => {
        if (isEnabled === 1) {
            event.target.parentElement.parentElement.parentElement.className = "new-data"
            setIsEnabled(0)
            setPassword("")
        }
    }

    const enableUserDelete = (event) => {
        if (isEnabledUs === 0) {
            event.currentTarget.lastChild.className = "del-options del-enabled"
            setIsEnabledUs(1)
        }
    }

    const disableUserDelete = (event) => {
        if (isEnabledUs === 1) {
            event.target.parentElement.parentElement.parentElement.className = "del-options"
            setIsEnabledUs(0)
        }
    }

    return (
        <div className='personal-data-component'>
            <h3>Informacje o koncie</h3>
            <p>Zarządzaj swoimi danymi osobowymi. Możesz je zmienić w każdej chwili, a także usunąć konto.</p>
            <div className='personal-data'>
                <div className='email' onClick={(e) => enableChangeOption(e)}>
                    <h4>Email</h4>
                    <p>{auth?.currentUser?.email}</p>
                    <Icon icon="ic:round-mail" />

                    <div className='new-data'>
                        <div className='new-data-block'>
                            <p>Wpisz swój nowy email i potwierdź hasłem:</p>
                            <input type='email' placeholder='Nowy email...' onChange={(e) => setNewEmail(e.target.value)}></input>
                            <input type="password" placeholder='Potwierdź hasłem...' onChange={(e) => setPassword(e.target.value)}></input>
                            <div className='controls'>
                                <button onClick={(e) => disableChangeOption(e)}>Anuluj</button>
                                <button onClick={changeEmail}>Zatwierdź</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='name' onClick={(e) => enableChangeOption(e)}>
                    <h4>Imię i nazwisko</h4>
                    <p>{auth?.currentUser?.displayName}</p>
                    <Icon icon="solar:user-bold" />

                    <div className='new-data'>
                        <div>
                            <p>Wpisz swóje nowe imię i nazwisko oraz potwierdź hasłem:</p>
                            <input placeholder='Nowa nazwa...' onChange={(e) => setNewName(e.target.value)}></input>
                            <input type="password" placeholder='Potwierdź hasłem...' onChange={(e) => setPassword(e.target.value)}></input>
                            <div className='controls'>
                                <button onClick={(e) => disableChangeOption(e)}>Anuluj</button>
                                <button onClick={changeName}>Zatwierdź</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='password' onClick={(e) => enableChangeOption(e)}>
                    <h4>Hasło</h4>
                    <p>**********</p>
                    <Icon icon="mdi:password" />

                    <div className='new-data'>
                        <div>
                            <p>Wpisz nowe hasło i potwierdź starym hasłem:</p>
                            <input placeholder='Nowe hasło...' onChange={(e) => setNewPassword(e.target.value)}></input>
                            <input type="password" placeholder='Potwierdź starym hasłem...' onChange={(e) => setPassword(e.target.value)}></input>
                            <div className='controls'>
                                <button onClick={(e) => disableChangeOption(e)}>Anuluj</button>
                                <button onClick={changePass}>Zatwierdź</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='delete-user' onClick={enableUserDelete}>
                <h4>Usuń konto</h4>
                <p>Usuń swoje konto na stałe z serwisu:</p>
                <Icon icon="mingcute:delete-fill" />
                <div className='del-options'>
                    <div className='delete-user-form'>
                        <p>Aby usunąć konto musisz wpisać swoje aktualne hasło</p>
                        <input type="password" placeholder='Potwierdź hasłem...' onChange={(e) => setPassword(e.target.value)}></input>
                        <div className='del-controls'>
                            <button onClick={disableUserDelete}>Anuluj</button>
                            <button onClick={deleteUserFromDb}>Usuń konto</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default AccountInfo